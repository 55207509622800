import React, { useEffect, useMemo } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  GAME_TYPES,
  GAME_TYPE_RATING,
  GAME_TYPE_UNRANKED,
  GAME_TYPE_TEST,
  GAME_TYPE_TOURNAMENT,
  LOCAL_STORAGE_COUNT,
  LOCAL_STORAGE_PLACE,
  LOCAL_STORAGE_RECOVERY,
  LOCAL_STORAGE_TYPE,
  GAME_TYPE_CHILD,
  LOCAL_STORAGE_CLUB,
  LOCAL_STORAGE_CITY,
} from "../../constants";
import { api } from "../../../../shared/user-api";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(4, 2),
    maxWidth: 1225,
    margin: "auto",
    textAlign: "center",
  },
  formContainer: {
    display: "grid",
    width: "fit-content",
    margin: "auto",
    padding: theme.spacing(1),
    border: "1px solid #333",
    borderRadius: "5px",
    marginTop: theme.spacing(4),
    maxWidth: "286px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    textAlign: "initial",
  },
  button: {
    margin: theme.spacing(1, 0, 0),
    border: "1px solid #fff",
  },
}));

function Admin({ onCreateGame, onLoadGame, clubs, isCreating }) {
  const classes = useStyles();

  const [recovery, setRecovery] = React.useState(false);
  const [type, setType] = React.useState(GAME_TYPE_RATING);
  const [club, setClub] = React.useState("");
  const [city, setCity] = React.useState("");
  const [place, setPlace] = React.useState("");
  const [count, setCount] = React.useState(3);
  const [placesList, setPlacesList] = React.useState([]);
  const fileInput = React.useRef();

  // useEffect(() => {
  //   let cached_recovery =
  //     localStorage.getItem(LOCAL_STORAGE_RECOVERY) === "true";
  //   let cached_type = localStorage.getItem(LOCAL_STORAGE_TYPE);
  //   let cached_place = sessionStorage.getItem(LOCAL_STORAGE_PLACE);
  //   let cached_count =
  //     parseInt(localStorage.getItem(LOCAL_STORAGE_COUNT)) || 10;

  //   setRecovery(cached_recovery);

  //   if (cached_type && GAME_TYPES.includes(cached_type)) setType(cached_type);

  //   if (cached_count <= 16 && cached_count >= 3) setCount(cached_count);

  //   if (!place && placesList.length > 0) {
  //     if (cached_place && placesList.map((pl) => pl.id).includes(cached_place))
  //       setPlace(cached_place);
  //     // else setPlace(placesList[0].id);
  //   }
  // }, [placesList]);

  const selectedClub = useMemo(
    () => clubs.find((clubItem) => clubItem.id == club),
    [clubs, club]
  );

  useEffect(() => {
    let cached_recovery =
      localStorage.getItem(LOCAL_STORAGE_RECOVERY) === "true";
    let cached_type = localStorage.getItem(LOCAL_STORAGE_TYPE);
    let cached_club = localStorage.getItem(LOCAL_STORAGE_CLUB);
    let cached_city = localStorage.getItem(LOCAL_STORAGE_CITY);
    let cached_count =
      parseInt(localStorage.getItem(LOCAL_STORAGE_COUNT)) || 10;

    setRecovery(cached_recovery);

    if (cached_type && GAME_TYPES.includes(cached_type)) setType(cached_type);

    if (cached_count <= 16 && cached_count >= 3) setCount(cached_count);

    let cachedClubData = null;

    if (!club && clubs.length > 0) {
      if (cached_club && clubs.map((pl) => `${pl.id}`).includes(cached_club)) {
        setClub(cached_club);
        cachedClubData = clubs.find((pl) => pl.id == cached_club);
      } else {
        setClub(clubs[0].id);
        cachedClubData = clubs[0];
      }
      // else setPlace(placesList[0].id);
    }

    if (cachedClubData && cached_city && cachedClubData.cities.length > 0) {
      if (
        cached_city &&
        cachedClubData.cities.map((pl) => `${pl.id}`).includes(cached_city)
      ) {
        setCity(cached_city);
      } else {
        setCity(cachedClubData.cities[0].id);
      }
    } else if (cachedClubData && cachedClubData.cities.length > 0) {
      setCity(cachedClubData.cities[0].id);
    }
  }, [clubs, selectedClub]);

  useEffect(() => {
    if (!club || !city) return;

    setPlacesList([]);
    setPlace("");

    const loadPlaces = async () => {
      const placesRes = await api.user.getPlaygrounds({
        club,
        city,
      });

      if (!placesRes.success) return;

      setPlacesList(placesRes.playgrounds);

      let cached_place = sessionStorage.getItem(LOCAL_STORAGE_PLACE);

      if (cached_place && placesRes.playgrounds.length > 0) {
        if (
          placesRes.playgrounds.map((pl) => `${pl.id}`).includes(cached_place)
        )
          setPlace(cached_place);
      }
    };

    loadPlaces();
  }, [club, city]);

  const handleRecoveryChange = (event) => {
    let newValue = event.target.checked;
    setRecovery(newValue);
    localStorage.setItem(LOCAL_STORAGE_RECOVERY, newValue);
  };

  const handlePlaceChange = (event) => {
    let newValue = parseInt(event.target.value);
    setPlace(newValue);
    sessionStorage.setItem(LOCAL_STORAGE_PLACE, newValue);
  };

  const handleClubChange = (event) => {
    let newValue = parseInt(event.target.value);
    setClub(newValue);
    localStorage.setItem(LOCAL_STORAGE_CLUB, newValue);
  };

  const handleCityChange = (event) => {
    let newValue = parseInt(event.target.value);
    setCity(newValue);
    localStorage.setItem(LOCAL_STORAGE_CITY, newValue);
  };

  const handleTypeChange = (event) => {
    let newValue = event.target.value;
    setType(newValue);
    localStorage.setItem(LOCAL_STORAGE_TYPE, newValue);
  };

  const handleCountChange = (event) => {
    let newValue = parseInt(event.target.value);
    setCount(newValue);
    localStorage.setItem(LOCAL_STORAGE_COUNT, newValue);
  };

  function valuetext(value) {
    return `${value} чел.`;
  }

  const createGame = async () => {
    if (type && place && count > 0) onCreateGame(type, place, count, Number(club), Number(city));
  };

  const loadGame = async () => {
    onLoadGame(fileInput.current.files[0]);
  };

  return (
    <div className={classes.content}>
      {/* <Typography variant="h3">Привет, {me?.first_name}!</Typography> */}
      <div className={classes.formContainer}>
        <FormControl className={classes.formControl}>
          <InputLabel id="club-select-label">Клуб</InputLabel>
          <Select
            labelId="club-select-label"
            id="club-select"
            value={club}
            onChange={handleClubChange}
          >
            {clubs &&
              clubs.map((clubItem) => (
                <MenuItem key={clubItem.id} value={clubItem.id}>
                  {clubItem.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="city-select-label">Город</InputLabel>
          <Select
            labelId="city-select-label"
            id="city-select"
            value={city}
            onChange={handleCityChange}
          >
            {selectedClub &&
              selectedClub.cities.map((cityItem) => (
                <MenuItem key={cityItem.id} value={cityItem.id}>
                  {cityItem.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="place-select-label">Площадка</InputLabel>
          <Select
            labelId="place-select-label"
            id="place-select"
            value={place}
            onChange={handlePlaceChange}
          >
            {placesList &&
              placesList
                .filter((place) => place.id != 5)
                .map((place) => (
                  <MenuItem key={place.id} value={place.id}>
                    {place.title}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="type-select-label">Тип игры</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select"
            value={type}
            onChange={handleTypeChange}
          >
            <MenuItem key={GAME_TYPE_RATING} value={GAME_TYPE_RATING}>
              Рейтинговая
            </MenuItem>
            <MenuItem key={GAME_TYPE_UNRANKED} value={GAME_TYPE_UNRANKED}>
              НЕ рейтинговая
            </MenuItem>
            <MenuItem key={GAME_TYPE_CHILD} value={GAME_TYPE_CHILD}>
              Детская
            </MenuItem>
            <MenuItem key={GAME_TYPE_TOURNAMENT} value={GAME_TYPE_TOURNAMENT}>
              Турнир
            </MenuItem>
            <MenuItem key={GAME_TYPE_TEST} value={GAME_TYPE_TEST}>
              Тест
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="count-input"
            label="Кол-во игроков"
            variant="standard"
            type="number"
            value={count}
            onChange={handleCountChange}
            InputProps={{
              inputProps: {
                max: 16,
                min: 3,
              },
            }}
          />
          {/* <Typography
						id="count-slider-label"
						gutterBottom
						color="textSecondary"
						variant="caption"
						component="label"
					>
						Кол-во игроков
					</Typography>
					<Slider
						aria-labelledby="count-slider-label"
						getAriaValueText={valuetext}
						defaultValue={3}
						step={1}
						min={3}
						max={15}
						value={count}
						onChange={handleCountChange}
						valueLabelDisplay="off"
						marks={[3, 5, 7, 9, 11, 13, 15].map((count) => ({
							value: count,
							label: count,
						}))}
					/> */}
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={recovery} onChange={handleRecoveryChange} />
              }
              label="Восстановить номера карт с прошлой игры"
            />
          </FormGroup>
        </FormControl>
        <Button className={classes.button} onClick={createGame} disabled={isCreating}>
          {isCreating ? "Создание..." : "Создать игру"}
        </Button>
      </div>

      <div className={classes.formContainer}>
        <FormControl className={classes.formControl}>
          <Typography
            id="count-slider-label"
            gutterBottom
            color="textSecondary"
            variant="caption"
            component="label"
          >
            Файл с сохранённой игрой
          </Typography>
          <input
            labelId="file-select-label"
            id="file-select"
            type="file"
            ref={fileInput}
          />
        </FormControl>
        <Button className={classes.button} onClick={loadGame}>
          Загрузить игру
        </Button>
      </div>
    </div>
  );
}

export default Admin;
