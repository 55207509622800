import { coreApi } from "../../core";

export const getCard = (cardId) =>
  coreApi
    .get(`/admin-rating/card/${cardId}`)
    .then((res) => res.data)
    .catch(() => ({ success: false }));

export const updateCard = (cardId, payload) =>
  coreApi
    .post(`/admin-rating/card/${cardId}`, payload)
    .then((res) => res.data)
    .catch(() => ({ success: false }));
