import { coreApi } from "../../core";

export const sendSignInCode = (payload) =>
  coreApi.post("/admin-rating/auth/sign-in-code", payload).then((res) => res.data);

export const signIn = (payload) =>
  coreApi
    .post("/admin-rating/auth/sign-in", payload, {
      withCredentials: true,
    })
    .then((res) => {
      const data = res.data;

      if (data.success && data.token) {
        localStorage.setItem("__md_token", data.token);
      }

      return data;
    });

export const signOut = () =>
  coreApi
    .get("/admin-rating/auth/sign-out", {
      withCredentials: true,
    })
    .then((res) => {
      const data = res.data;

      localStorage.removeItem("__md_token");

      return data;
    });
