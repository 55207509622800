import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(4, 2),
    maxWidth: 1225,
    margin: "auto",
    textAlign: "center",
  },
  container: {
    // marginTop: theme.spacing(2),
    // maxHeight: "500px"
  },
  chips: {
    margin: theme.spacing(2, 0, 1),
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

function Rating() {
  const classes = useStyles();

  const [ratingData, setRatingData] = useState(null);
  const [ratingRangesData, setRatingRangesData] = useState(null);
  const [rangeId, setRangeId] = React.useState(null);
  const [clubId, setClubId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  // useEffect(() => {
  //   async function fetchData() {
  //     let ratingRequest = await fetch(
  //       "https://db.mafdrive.ru/rating_api_ts/rating_ranges?access_token=ratingmdru",
  //       {
  //         headers: {
  //           accept: "application/json, text/plain, */*",
  //           "cache-control": "no-store",
  //         },
  //         method: "GET",
  //       }
  //     );

  //     let rangesData = await ratingRequest.json();
  //     setRatingRangesData(rangesData);
  //     setClubId(rangesData[0].id);
  //     setRangeId(rangesData[0].ranges[0].id);
  //   }
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (rangeId) {
  //     async function fetchData() {
  //       let ratingRequest = await fetch(
  //         `https://db.mafdrive.ru/rating_api_ts/rating?id=${rangeId}&access_token=ratingmdru`,
  //         {
  //           headers: {
  //             accept: "application/json, text/plain, */*",
  //             "cache-control": "no-store",
  //           },
  //           method: "GET",
  //         }
  //       );
  //       setRatingData(await ratingRequest.json());
  //     }
  //     fetchData();
  //   }
  // }, [rangeId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectClub = (club) => () => {
    setRatingData(null);
    let _clubData = ratingRangesData?.find((_club) => _club.id == club);
    setClubId(club);
    setRangeId(_clubData?.ranges[0]?.id ?? null);
    setPage(0);
  };

  const handleSelectRange = (range) => () => {
    setRatingData(null);
    setRangeId(range);
    setPage(0);
  };

  const clubData = ratingRangesData?.find((club) => club.id == clubId);
  // const rangeData = clubData?.ranges?.find((range) => range.id == rangeId);

  return (
    <main className={classes.content}>
      <Typography variant="h3">Рейтинг</Typography>
      {!ratingRangesData ? (
        <Typography variant="caption" color="textSecondary">
          Подождите, идёт загрузка...
        </Typography>
      ) : (
        <>
          <div className={classes.chips}>
            {(ratingRangesData ?? []).map((club, index) => (
              <Chip
                size="small"
                label={club.name}
                variant={club.id === clubId ? "default" : "outlined"}
                onClick={
                  club.id === clubId ? undefined : handleSelectClub(club.id)
                }
              />
            ))}
          </div>
          <div className={classes.chips}>
            {(clubData?.ranges ?? []).map((range, index) => (
              <Chip
                size="small"
                label={range.name}
                variant={range.id === rangeId ? "default" : "outlined"}
                onClick={
                  range.id === rangeId ? undefined : handleSelectRange(range.id)
                }
              />
            ))}
          </div>
          {!ratingData ? (
            <Typography variant="caption" color="textSecondary">
              Подождите, идёт загрузка...
            </Typography>
          ) : (
            <>
              <TableContainer className={classes.container} component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="Рейтинг"
                  // stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">№</TableCell>
                      <TableCell align="center">Карта</TableCell>
                      <TableCell align="center">Всего сыграно игр</TableCell>
                      <TableCell align="center">Всего побед</TableCell>
                      <TableCell align="center">
                        Коэффициент эффективности
                      </TableCell>
                      {/* <TableCell align="center">Мирынй</TableCell>
									<TableCell align="center">Доктор</TableCell>
									<TableCell align="center">Комиссар</TableCell>
									<TableCell align="center">Путана</TableCell>
									<TableCell align="center">Камикадзе</TableCell>
									<TableCell align="center">Сапер</TableCell>
									<TableCell align="center">Мафия</TableCell>
									<TableCell align="center">Дон</TableCell>
									<TableCell align="center">Маньяк</TableCell> */}
                      <TableCell align="center">ОИ</TableCell>
                      <TableCell align="center">ОБ</TableCell>
                      <TableCell align="center">Баллы</TableCell>
                      <TableCell align="center">Дополнительные баллы</TableCell>
                      <TableCell align="center">Штрафы</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(ratingData?.data ?? [])
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((iCard) => (
                        <TableRow key={iCard.card}>
                          <TableCell align="center">{iCard._n}</TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <Link
                              to={`/card/${iCard.card}`}
                              style={{ color: "#e6bc53" }}
                            >
                              {iCard.card}
                            </Link>
                          </TableCell>
                          <TableCell align="center">
                            {iCard.all_count}
                          </TableCell>
                          <TableCell align="center">{iCard.all_win}</TableCell>
                          <TableCell align="center">
                            {iCard._efficiency}
                          </TableCell>
                          {/* <TableCell align="center">{iCard._citizen}</TableCell>
											<TableCell align="center">{iCard._doctor}</TableCell>
											<TableCell align="center">{iCard._commissar}</TableCell>
											<TableCell align="center">{iCard._putana}</TableCell>
											<TableCell align="center">{iCard._kamikaze}</TableCell>
											<TableCell align="center">{iCard._martyr}</TableCell>
											<TableCell align="center">{iCard._mafia}</TableCell>
											<TableCell align="center">{iCard._don}</TableCell>
											<TableCell align="center">{iCard._maniac}</TableCell> */}
                          <TableCell align="center">{iCard.oi}</TableCell>
                          <TableCell align="center">{iCard.ob}</TableCell>
                          <TableCell align="center">{iCard._points}</TableCell>
                          <TableCell align="center">
                            {iCard.additional_points}
                          </TableCell>
                          <TableCell align="center">{iCard.penalty}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 200, 500]}
                component="div"
                count={ratingData?.data?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </>
      )}
    </main>
  );
}

export default Rating;
