import React, { useEffect, useState } from "react";
import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	TablePagination,
	Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(4, 2),
		maxWidth: 1225,
		margin: "auto",
		textAlign: "center",
	},
	container: {
		// marginTop: theme.spacing(2),
		// maxHeight: "500px"
	},
	chips: {
		margin: theme.spacing(2, 0, 1),
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

function League() {
	const classes = useStyles();

	const [ratingData, setRatingData] = useState([]);
	const [ratingPage, setRatingPage] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);

	useEffect(() => {
		async function fetchData() {
			let ratingRequest = await fetch(
				"https://db.mafdrive.ru/rating_api/league?access_token=ratingmdru",
				{
					headers: {
						accept: "application/json, text/plain, */*",
						"cache-control": "no-store",
					},
					method: "GET",
				}
			);
			setRatingData(await ratingRequest.json());
		}
		fetchData();
	}, []);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectRange = (range) => () => {
		setRatingPage(ratingData.data.findIndex((r) => r.name == range.name));
		setPage(0);
	};

	return (
		<main className={classes.content}>
			<Typography variant="h3">Лига</Typography>
			<Typography variant="subtitle1">{ratingData?.name || ""}</Typography>
			{ratingData.length == 0 ? (
				<Typography variant="caption" color="textSecondary">
					Подождите, идёт загрузка...
				</Typography>
			) : (
				<>
					<div className={classes.chips}>
						{ratingData.data.map((range, index) => (
							<Chip
								size="small"
								label={range.name}
								variant={index === ratingPage ? "default" : "outlined"}
								onClick={
									index === ratingPage ? undefined : handleSelectRange(range)
								}
							/>
						))}
					</div>
					<TableContainer className={classes.container} component={Paper}>
						<Table
							className={classes.table}
							size="small"
							aria-label="Лига"
							// stickyHeader
						>
							<TableHead>
								<TableRow>
									<TableCell align="center">№</TableCell>
									<TableCell align="center">Карта</TableCell>
									<TableCell align="center">Всего сыграно игр</TableCell>
									<TableCell align="center">Всего побед</TableCell>
									<TableCell align="center">
										Коэффициент эффективности
									</TableCell>
									<TableCell align="center">Баллы</TableCell>
									{/* <TableCell align="center">Мирынй</TableCell>
									<TableCell align="center">Доктор</TableCell>
									<TableCell align="center">Комиссар</TableCell>
									<TableCell align="center">Путана</TableCell>
									<TableCell align="center">Камикадзе</TableCell>
									<TableCell align="center">Сапер</TableCell>
									<TableCell align="center">Мафия</TableCell>
									<TableCell align="center">Дон</TableCell>
									<TableCell align="center">Маньяк</TableCell>
									<TableCell align="center">ОИ</TableCell>
									<TableCell align="center">ОБ</TableCell> */}
									<TableCell align="center">Дополнительные баллы</TableCell>
									<TableCell align="center">Штрафы</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ratingData?.data.length > 0 &&
									ratingData.data[ratingPage].data
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((iCard) => {
											return (
												<>
													<TableRow key={iCard.card}>
														<TableCell align="center">{iCard._n}</TableCell>
														<TableCell
															align="center"
															component="th"
															scope="row"
														>
															<Link
																to={`/card/${iCard.card}`}
																style={{ color: "#e6bc53" }}
															>
																{iCard.card}
															</Link>
														</TableCell>
														<TableCell align="center">
															{iCard.all_count}
														</TableCell>
														<TableCell align="center">
															{iCard.all_win}
														</TableCell>
														<TableCell align="center">
															{iCard._efficiency}
														</TableCell>
														<TableCell align="center">
															{iCard._points}
														</TableCell>
														<TableCell align="center">
															{iCard.additional_points}
														</TableCell>
														<TableCell align="center">
															{iCard.penalty}
														</TableCell>
													</TableRow>
                                                    {ratingPage>0&&
														ratingData.data[ratingPage-1].prev_count ==
														iCard._n && (
														<TableRow key="top">
															<TableCell colSpan="8" align="center">
                                                            ⬆⬆⬆ ПЕРЕХОДЯТ В СЛЕДУЮЩУЮ ЛИГУ ⬆⬆⬆
															</TableCell>
														</TableRow>
													)}
													{ratingData.data[ratingPage].count -
														ratingData.data[ratingPage].prev_count ==
														iCard._n && (
														<TableRow key="top">
															<TableCell colSpan="8" align="center">
																⬇⬇⬇ ПЕРЕХОДЯТ В ПРЕДЫДУЩУЮ ЛИГУ ⬇⬇⬇
															</TableCell>
														</TableRow>
													)}
												</>
											);
										})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[50, 100, 200, 500]}
						component="div"
						count={ratingData.data[ratingPage].data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</main>
	);
}

export default League;
