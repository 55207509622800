import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Select,
  MenuItem,
  Input,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  IconButton,
  Typography,
  styled,
} from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@material-ui/core/Tooltip";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";
import structuredClone from "@ungap/structured-clone";
import { CloudDownloadOutlined, HistoryRounded } from "@material-ui/icons";

import { LOCAL_STORAGE_CARDS, LOCAL_STORAGE_RECOVERY } from "../../constants";
import { api } from "../../../../shared/user-api";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(4, 2),
    // maxWidth: 1225,
    margin: "auto",
    textAlign: "center",
  },
  container: {
    margin: theme.spacing(0, 0, 2),
    // maxHeight: "500px"
  },
  select: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
  sizeSmall: {
    padding: "6px 8px 6px 8px",
  },
  cardnum: {
    textAlign: "center",
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  alertMessage: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  alertButton: {
    marginLeft: "auto",
    padding: 0,
  },
  alertSelect: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  control: {
    maxWidth: "600px",
    margin: "auto",
  },
  controlButtons: {
    maxWidth: "800px",
    margin: "auto",
  },
}));

// Init ROLES constants
const ROLE_CITIZEN = "citizen";
const ROLE_MAFIA = "mafia";
const ROLE_COMMISSAR = "commissar";
const ROLE_DOCTOR = "doctor";
const ROLE_MANIAC = "maniac";
const ROLE_KAMIKAZE = "kamikaze";
const ROLE_MARTYR = "martyr";
const ROLE_DON = "don";
const ROLE_PUTANA = "putana";

// Init EVENTS constants
const EVENT_TALK = "talk";
const EVENT_START = "start";
const EVENT_WIN_NOBODY = "win_nobody";
const EVENT_WIN_CITIZEN = "win_citizen";
const EVENT_WIN_MAFIA = "win_mafia";
const EVENT_WIN_MANIAC = "win_maniac";
const EVENT_TECH_LOSE_CITIZEN = "tech_lose_citizen";
const EVENT_TECH_LOSE_CITIZEN_NO_MAFIA = "tech_lose_citizen_no_mafia";
const EVENT_TECH_LOSE_CITIZEN_NO_MANIAC = "tech_lose_citizen_no_maniac";
const EVENT_TECH_LOSE_MAFIA = "tech_lose_mafia";
const EVENT_TECH_LOSE_MAFIA_NO_CITIZEN = "tech_lose_mafia_no_citizen";
const EVENT_TECH_LOSE_MAFIA_NO_MANIAC = "tech_lose_mafia_no_maniac";
const EVENT_TECH_LOSE_MANIAC = "tech_lose_maniac";
const EVENT_TECH_LOSE_MANIAC_NO_CITIZEN = "tech_lose_maniac_no_citizen";
const EVENT_TECH_LOSE_MANIAC_NO_MAFIA = "tech_lose_maniac_no_mafia";

const CUSTOM_EVENTS = [
  EVENT_TALK,
  EVENT_START,
  EVENT_WIN_CITIZEN,
  EVENT_WIN_MAFIA,
  EVENT_WIN_MANIAC,
  EVENT_TECH_LOSE_CITIZEN,
  EVENT_TECH_LOSE_MAFIA,
  EVENT_TECH_LOSE_MANIAC,
  EVENT_WIN_NOBODY,
  EVENT_TECH_LOSE_CITIZEN_NO_MAFIA,
  EVENT_TECH_LOSE_CITIZEN_NO_MANIAC,
  EVENT_TECH_LOSE_MAFIA_NO_CITIZEN,
  EVENT_TECH_LOSE_MAFIA_NO_MANIAC,
  EVENT_TECH_LOSE_MANIAC_NO_CITIZEN,
  EVENT_TECH_LOSE_MANIAC_NO_MAFIA,
];

// Init DAYTIME constants
const DAYTIME_START = { id: "S", name: "Ночь Знакомств" };
const DAYTIME_DAY = { id: "D", name: "День" };
const DAYTIME_DAY_KAMIKAZE = { id: "DK", name: "День Камикадзе" };
const DAYTIME_DAY_MARTYR = { id: "DM", name: "День Сапёра" };
const DAYTIME_DAY_TALK = { id: "DT", name: "Обсуждение" };
const DAYTIME_NIGHT = { id: "N", name: "Ночь" };
const DAYTIME_NIGHT_MANIAC = { id: "NM", name: "Ночь Маньяка" };
const DAYTIME_NIGHT_KAMIKAZE = { id: "NK", name: "Ночь Камикадзе" };
const DAYTIME_NIGHT_MARTYR = { id: "NMA", name: "Ночь Сапёра" };

const DAYTIME_WIN_NOBODY = { id: "WN", name: "Ничья" };

const DAYTIME_WIN_CITIZEN = { id: "WC", name: "Победа Мирных" };
const DAYTIME_WIN_MAFIA = { id: "WMAF", name: "Победа Мафии" };
const DAYTIME_WIN_MANIAC = { id: "WMAN", name: "Победа Маньяка" };

const DAYTIME_TECH_LOSE_CITIZEN = { id: "TLC", name: "Тех. поражение Мирных" };
const DAYTIME_TECH_LOSE_CITIZEN_NO_MAFIA = {
  id: "TLCNOMAF",
  name: "Тех. поражение Мирных (только Маньяк)",
};
const DAYTIME_TECH_LOSE_CITIZEN_NO_MANIAC = {
  id: "TLCNOMAN",
  name: "Тех. поражение Мирных (только Мафия)",
};
const DAYTIME_TECH_LOSE_MAFIA = { id: "TLMAF", name: "Тех. поражение Мафии" };
const DAYTIME_TECH_LOSE_MAFIA_NO_CITIZEN = {
  id: "TLMAFNOCIT",
  name: "Тех. поражение Мафии (только Маньяк)",
};
const DAYTIME_TECH_LOSE_MAFIA_NO_MANIAC = {
  id: "TLMAFNOMAN",
  name: "Тех. поражение Мафии (только Мирные)",
};
const DAYTIME_TECH_LOSE_MANIAC = {
  id: "TLMAN",
  name: "Тех. поражение Маньяка",
};
const DAYTIME_TECH_LOSE_MANIAC_NO_MAFIA = {
  id: "TLMAN",
  name: "Тех. поражение Маньяка (только Мирные)",
};
const DAYTIME_TECH_LOSE_MANIAC_NO_CITIZEN = {
  id: "TLMAN",
  name: "Тех. поражение Маньяка (только Мафия)",
};

// Init SUCCESS_SELECTS constants
const SUCCESS_SELECTS = {
  [ROLE_CITIZEN]: [],
  [ROLE_MAFIA]: [],
  [ROLE_DON]: [ROLE_DOCTOR, ROLE_COMMISSAR, ROLE_PUTANA],
  [ROLE_DOCTOR]: [
    ROLE_CITIZEN,
    ROLE_COMMISSAR,
    ROLE_DOCTOR,
    ROLE_KAMIKAZE,
    ROLE_MARTYR,
    ROLE_PUTANA,
  ],
  [ROLE_COMMISSAR]: [ROLE_MAFIA, ROLE_DON],
  [ROLE_KAMIKAZE]: [ROLE_MAFIA, ROLE_DON, ROLE_MANIAC],
  [ROLE_MANIAC]: [],
  [ROLE_MARTYR]: [],
  [ROLE_PUTANA]: [ROLE_MAFIA, ROLE_DON, ROLE_MANIAC],
};

// Init QUEUE_START constant
const QUEUE_START = {
  role: EVENT_START,
  select: false,
  text: "Заполните роли, затем начните игру",
  buttonText: "Начать",
  locked: false,
  history: false,
  nums: [],
  button: true,
  logs: true,
};

const QUEUE_MASTER = {
  [DAYTIME_START.id]: [EVENT_TALK, ROLE_CITIZEN],
  [DAYTIME_DAY.id]: [EVENT_TALK, ROLE_CITIZEN],
  [DAYTIME_NIGHT.id]: [
    ROLE_PUTANA,
    ROLE_MAFIA,
    ROLE_DON,
    ROLE_MANIAC,
    ROLE_DOCTOR,
    ROLE_COMMISSAR,
  ],
  [DAYTIME_NIGHT_MANIAC.id]: [ROLE_MANIAC],
  [DAYTIME_DAY_KAMIKAZE.id]: [ROLE_KAMIKAZE],
  [DAYTIME_NIGHT_KAMIKAZE.id]: [ROLE_KAMIKAZE],
  [DAYTIME_DAY_MARTYR.id]: [ROLE_MARTYR],
  [DAYTIME_NIGHT_MARTYR.id]: [ROLE_MARTYR],
  [DAYTIME_DAY_TALK.id]: [EVENT_TALK],
  [DAYTIME_WIN_NOBODY.id]: [EVENT_WIN_NOBODY],
  [DAYTIME_WIN_CITIZEN.id]: [EVENT_WIN_CITIZEN],
  [DAYTIME_WIN_MAFIA.id]: [EVENT_WIN_MAFIA],
  [DAYTIME_WIN_MANIAC.id]: [EVENT_WIN_MANIAC],
  [DAYTIME_TECH_LOSE_CITIZEN.id]: [EVENT_TECH_LOSE_CITIZEN],
  [DAYTIME_TECH_LOSE_CITIZEN_NO_MAFIA.id]: [EVENT_TECH_LOSE_CITIZEN_NO_MAFIA],
  [DAYTIME_TECH_LOSE_CITIZEN_NO_MANIAC.id]: [EVENT_TECH_LOSE_CITIZEN_NO_MANIAC],
  [DAYTIME_TECH_LOSE_MAFIA.id]: [EVENT_TECH_LOSE_MAFIA],
  [DAYTIME_TECH_LOSE_MAFIA_NO_CITIZEN.id]: [EVENT_TECH_LOSE_MAFIA_NO_CITIZEN],
  [DAYTIME_TECH_LOSE_MAFIA_NO_MANIAC.id]: [EVENT_TECH_LOSE_MAFIA_NO_MANIAC],
  [DAYTIME_TECH_LOSE_MANIAC.id]: [EVENT_TECH_LOSE_MANIAC],
  [DAYTIME_TECH_LOSE_MANIAC_NO_CITIZEN.id]: [EVENT_TECH_LOSE_MANIAC_NO_CITIZEN],
  [DAYTIME_TECH_LOSE_MANIAC_NO_MAFIA.id]: [EVENT_TECH_LOSE_MANIAC_NO_MAFIA],
};

const TURNS_DATA = {
  [ROLE_CITIZEN]: {
    wake: true,
    select: true,
    history: true,
    canPutanaLock: false,
    deadLock: "Жители мертвы",
    nobodyLock: "Жители никого не выгоняют",
    maniacNightLock: "Начинается ночь маньяка",
    doubleNightLock: "Жители совершают двойное убийство",
    putanaLock: "",
    text: "Жители выбирают игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_PUTANA]: {
    wake: true,
    select: true,
    history: true,
    canPutanaLock: false,
    deadLock: "Путана мертва",
    nobodyLock: "Путана никого не соблазняет",
    putanaLock: "",
    text: "Путана выбирает игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_MAFIA]: {
    wake: true,
    select: true,
    history: true,
    canPutanaLock: true,
    deadLock: "Мафия мертва",
    nobodyLock: "Мафия никого не убивает",
    putanaLock: "Мафия загуляла с Путаной",
    text: "Мафия выбирает игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_DON]: {
    wake: true,
    select: true,
    history: true,
    canPutanaLock: true,
    deadLock: "Дон мёртв",
    nobodyLock: "Дон никого не проверяет",
    putanaLock: "Дон загулял с Путаной",
    text: "Дон проверяет игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_MANIAC]: {
    wake: true,
    select: true,
    history: true,
    canPutanaLock: true,
    deadLock: "Маньяк мёртв",
    nobodyLock: "Маньяк никого не убивает",
    putanaLock: "Маньяк загулял с Путаной",
    text: "Маньяк выбирает игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_DOCTOR]: {
    wake: true,
    select: true,
    history: true,
    canPutanaLock: true,
    deadLock: "Доктор мёртв",
    nobodyLock: "Доктор никого не лечит",
    putanaLock: "Доктор загулял с Путаной",
    text: "Доктор лечит игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_COMMISSAR]: {
    wake: true,
    select: true,
    history: true,
    canPutanaLock: true,
    deadLock: "Комиссар мёртв",
    nobodyLock: "Комиссар никого не проверяет",
    putanaLock: "Комиссар загулял с Путаной",
    text: "Комиссар проверяет игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_KAMIKAZE]: {
    wake: false,
    select: true,
    history: true,
    canPutanaLock: false,
    deadLock: "Камикадзе мёртв",
    nobodyLock: "Камикадзе никого не убивает",
    putanaLock: "Камикадзе загулял с Путаной",
    text: "Камикадзе выбирает игрока №",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [ROLE_MARTYR]: {
    wake: false,
    select: false,
    history: true,
    canPutanaLock: false,
    deadLock: "Сапёр мёртв",
    nobodyLock: "Сапёр никого не убивает",
    putanaLock: "Сапёр загулял с Путаной",
    text: "Сапёр взрывается",
    buttonText: "Далее",
    button: true,
    logs: false,
  },
  [EVENT_START]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Заполните роли, затем начните игру",
    buttonText: "Начать",
    button: true,
    logs: true,
  },
  [EVENT_TALK]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Обсуждение и голосование",
    deadLock: "Обсуждение и голосование",
    buttonText: "Далее",
    button: true,
    logs: true,
  },
  [EVENT_WIN_NOBODY]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Ничья!",
    deadLock: "Ничья!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_WIN_CITIZEN]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Победа Мирных!",
    deadLock: "Победа Мирных!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_WIN_MAFIA]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Победа Мафии!",
    deadLock: "Победа Мафии!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_WIN_MANIAC]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Победа Маньяка!",
    deadLock: "Победа Маньяка!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_CITIZEN]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Мирных!",
    deadLock: "Тех. поражение Мирных!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_CITIZEN_NO_MAFIA]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Мирных (только Маньяк)!",
    deadLock: "Тех. поражение Мирных (только Маньяк)!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_CITIZEN_NO_MANIAC]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Мирных (только Мафия)!",
    deadLock: "Тех. поражение Мирных (только Мафия)!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_MAFIA]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Мафии!",
    deadLock: "Тех. поражение Мафии!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_MAFIA_NO_CITIZEN]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Мафии (только Маньяк)!",
    deadLock: "Тех. поражение Мафии (только Маньяк)!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_MAFIA_NO_MANIAC]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Мафии (только Мирные)!",
    deadLock: "Тех. поражение Мафии (только Мирные)!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_MANIAC]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Маньяка!",
    deadLock: "Тех. поражение Маньяка!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_MANIAC_NO_CITIZEN]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Маньяка (только Мафия)!",
    deadLock: "Тех. поражение Маньяка (только Мафия)!",
    buttonText: "",
    button: false,
    logs: true,
  },
  [EVENT_TECH_LOSE_MANIAC_NO_MAFIA]: {
    wake: true,
    select: false,
    history: false,
    canPutanaLock: false,
    putanaLock: "",
    text: "Тех. поражение Маньяка (только Мирные)!",
    deadLock: "Тех. поражение Маньяка (только Мирные)!",
    buttonText: "",
    button: false,
    logs: true,
  },
};

const ROLES_LIST = {
  mafia: "М",
  commissar: "К",
  doctor: "док",
  maniac: "ман",
  kamikaze: "кам",
  martyr: "сап",
  don: "Дм",
  putana: "пут",
  citizen: "мир",
};

// const ROLES_LIST = {
// 	mafia: "Мафия",
// 	commissar: "Комиссар",
// 	doctor: "Доктор",
// 	maniac: "Маньяк",
// 	kamikaze: "Камикадзе",
// 	martyr: "Сапёр",
// 	don: "Дон",
// 	putana: "Путана",
// 	citizen: "Мирный",
// };

const RESULT_WIN_NOBODY = "nobody";
const RESULT_WIN_CITIZEN = "citizen_doctor_commissar_putana_kamikaze_martyr";
const RESULT_WIN_MAFIA = "mafia_don";
const RESULT_WIN_MANIAC = "maniac";
const RESULT_TECH_LOSE_MAFIA =
  "_teh_citizen_doctor_commissar_putana_kamikaze_martyr_maniac";
const RESULT_TECH_LOSE_MAFIA_NO_MANIAC =
  "_teh_m_citizen_doctor_commissar_putana_kamikaze_martyr";
const RESULT_TECH_LOSE_MAFIA_NO_CITIZEN = "_teh_m_maniac";
const RESULT_TECH_LOSE_CITIZEN = "_teh_maniac_mafia_don";
const RESULT_TECH_LOSE_CITIZEN_NO_MAFIA = "_teh_c_maniac";
const RESULT_TECH_LOSE_CITIZEN_NO_MANIAC = "_teh_c_mafia_don";
const RESULT_TECH_LOSE_MANIAC =
  "_teh_citizen_doctor_commissar_putana_kamikaze_martyr_mafia_don";
const RESULT_TECH_LOSE_MANIAC_NO_MAFIA =
  "_teh_mc_citizen_doctor_commissar_putana_kamikaze_martyr";
const RESULT_TECH_LOSE_MANIAC_NO_CITIZEN = "_teh_mc_mafia_don";

function Game() {
  const classes = useStyles();
  const { gameId } = useParams();

  const gHistory = useHistory();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [undoGameData, setUndoGameData] = useState([]);
  const [gameData, setGameData] = useState({});
  const [select, setSelect] = useState("");

  const [penalty, setPenalty] = useState("");
  const [openPenaltyDialog, setOpenPenaltyDialog] = useState(false);
  const [openPenalty50Dialog, setOpenPenalty50Dialog] = useState(false);

  const [techLose, setTechLose] = useState("");
  const [openTechLoseDialog, setOpenTechLoseDialog] = useState(false);

  const [killPlayerModal, setKillPlayerModal] = useState("");
  const [openKillPlayerDialog, setOpenKillPlayerDialog] = useState(false);

  // const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({}), []);

  const saveGame = async (lGameData) => {
    // setError(false);
    updateGamers(lGameData);
    try {
      let savedGameData = await api.games.updateGame(
        parseInt(gameId),
        lGameData
      );
      if (!savedGameData?.success) throw new Error("Error saving game");
      setError(false);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (!loading) saveGame(gameData);
  }, [gameData]);

  const getRatingCard = async (card) => {
    try {
      let ratingCardData = await api.cards.getCard(card);
      if (!ratingCardData?.success) throw new Error("Error getting card");
      return ratingCardData.card;
    } catch (error) {
      setError(true);
    }
    return null;
  };

  const updatePenaltyCount = async (card, count) => {
    try {
      let ratingCardData = await api.cards.updateCard(`${card}`, {
        penalty_count: count,
      });
      if (!ratingCardData?.success) throw new Error("Error updating card");
    } catch (error) {}
  };

  const newGame = async () => {
    gHistory.push("/admin");
  };

  const downloadGame = async () => {
    let lGameData = structuredClone(gameData);
    updateGamers(lGameData);
    let jsonData = JSON.stringify(lGameData);
    let blob = new Blob([jsonData], { type: "application/json;charset=utf-8" });
    let isIE = false || !!document.documentMode;
    if (isIE) {
      window.navigator.msSaveBlob(blob, `md_game_${gameId}.json`);
    } else {
      let url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.download = `md_game_${gameId}.json`;
      a.href = link;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      let gameRes;
      try {
        gameRes = await api.games.getGame(parseInt(gameId));
        if (!gameRes?.success) throw new Error("Error getting game");
      } catch (error) {
        setError(true);
        gHistory.push("/admin");
        return;
      }

      const savedGameData = gameRes.game;
      if (
        !savedGameData.data.playersData ||
        savedGameData.data.playersData.length == 0
      ) {
        // Init playersData
        let cached_recovery =
          localStorage.getItem(LOCAL_STORAGE_RECOVERY) === "true";
        let cached_cards = [];
        if (cached_recovery) {
          let cached_cards_str =
            localStorage.getItem(LOCAL_STORAGE_CARDS) || "";
          if (cached_cards_str) {
            try {
              cached_cards = JSON.parse(cached_cards_str);
            } catch (error) {
              cached_cards = [];
            }
          }
        }
        savedGameData.data.playersData = [];
        for (let i = 1; i <= savedGameData.count; i++) {
          savedGameData.data.playersData.push({
            num: i,
            alive: true,
            role: "",
            notes: "",
            card: cached_cards.length >= i ? `${cached_cards[i - 1]}` : "",
            db: 0,
            nums: [],
            vote: "",
            penalty: 0,
          });
        }

        // Init day & daytime
        savedGameData.data.day = 0;
        savedGameData.data.daytime = DAYTIME_START;

        // Init actions
        savedGameData.data.actions = {
          kamikaze: false,
          martyr: false,
        };

        // Init history
        savedGameData.data.history = [];

        // Init roleQueue
        savedGameData.data.roleQueue = [QUEUE_START];
      }

      setGameData(savedGameData);
    };
    if (gameId) {
      loadData();
    }
  }, [gameId]);

  useEffect(() => {
    if (gameData?.data?.playersData?.length > 0) {
      setLoading(false);
    }
  }, [gameData?.data?.playersData]);

  const nextDaytime = (iGameData, kamikaze = false, martyr = false) => {
    if (kamikaze) {
      if (
        [
          DAYTIME_NIGHT.id,
          DAYTIME_NIGHT_MARTYR.id,
          DAYTIME_NIGHT_KAMIKAZE.id,
          DAYTIME_NIGHT_MANIAC.id,
        ].includes(iGameData.daytime.id)
      )
        return DAYTIME_NIGHT_KAMIKAZE;
      else if (
        [
          DAYTIME_DAY.id,
          DAYTIME_DAY_KAMIKAZE.id,
          DAYTIME_DAY_MARTYR.id,
        ].includes(iGameData.daytime.id)
      )
        return DAYTIME_DAY_KAMIKAZE;
    } else if (martyr) {
      if (
        [
          DAYTIME_NIGHT.id,
          DAYTIME_NIGHT_MARTYR.id,
          DAYTIME_NIGHT_KAMIKAZE.id,
          DAYTIME_NIGHT_MANIAC.id,
        ].includes(iGameData.daytime.id)
      )
        return DAYTIME_NIGHT_MARTYR;
      else if (
        [
          DAYTIME_DAY.id,
          DAYTIME_DAY_KAMIKAZE.id,
          DAYTIME_DAY_MARTYR.id,
        ].includes(iGameData.daytime.id)
      )
        return DAYTIME_DAY_MARTYR;
    } else {
      switch (iGameData.daytime.id) {
        case DAYTIME_START.id:
          return DAYTIME_DAY;
        case DAYTIME_DAY.id:
          return DAYTIME_NIGHT;
        case DAYTIME_NIGHT.id:
          return DAYTIME_DAY;
        case DAYTIME_DAY_TALK.id:
          return DAYTIME_NIGHT;
        case DAYTIME_DAY_KAMIKAZE.id:
          return DAYTIME_NIGHT;
        case DAYTIME_DAY_MARTYR.id:
          return DAYTIME_NIGHT;
        case DAYTIME_NIGHT_KAMIKAZE.id:
          return DAYTIME_DAY;
        case DAYTIME_NIGHT_MANIAC.id:
          return DAYTIME_DAY_TALK;

        default:
          return DAYTIME_DAY;
      }
    }
  };

  const getAliveNumsByRole = (lPlayersData, role) => {
    let nNums = [];
    for (let player of lPlayersData)
      if (
        (player.role == role ||
          (role == ROLE_MAFIA && player.role == ROLE_DON)) &&
        player.alive
      )
        nNums.push(player.num);
    return nNums;
  };

  const getAliveNums = (lPlayersData) => {
    let nNums = [];
    if (Array.isArray(lPlayersData)) {
      for (let player of lPlayersData) if (player.alive) nNums.push(player.num);
    }
    return nNums;
  };

  const killPlayer = (pData, dayData, killNum, force = false) => {
    const simpleKill = (lDayData, lKillPlayer, lKillNum) => {
      lKillPlayer.alive = false;
      lDayData.killedNow.push(lKillNum);
    };

    let kPlayer = pData.find((pl) => pl.num == killNum);
    if (kPlayer && kPlayer.alive) {
      let role = kPlayer.role;

      if (!force && killNum == dayData.putanaLock) return;

      switch (role) {
        case ROLE_PUTANA:
          simpleKill(dayData, kPlayer, killNum);
          if (dayData.putanaLock) {
            killPlayer(pData, dayData, dayData.putanaLock, true);
          }
          return;

        case ROLE_KAMIKAZE:
          simpleKill(dayData, kPlayer, killNum);
          dayData.kamikaze = true;
          return;

        case ROLE_MARTYR:
          simpleKill(dayData, kPlayer, killNum);
          dayData.martyr = true;
          return;

        default:
          simpleKill(dayData, kPlayer, killNum);
          return;
      }
    }
  };

  const updateDBByResult = (lPlayersData, result) => {
    if (result) {
      let lTechLose = result.startsWith("_teh_");
      for (let player of lPlayersData) {
        if (lTechLose || !result.includes(player.role)) {
          player.db = 0;
        }
      }
    }
  };

  const onNewStage = (iGameObj, addHistory = true) => {
    if (!!iGameObj?.data?.roleQueue?.length) return;

    // let iGameObj = structuredClone(gameData);
    let iGameData = iGameObj.data;
    let currentDaytime = iGameData.daytime;
    let prevPlayersData = iGameData.playersData;

    console.log(iGameData.daytime.id);

    let activeRoles = [];
    for (let player of prevPlayersData) {
      if (!player.role) player.role = ROLE_CITIZEN;
      activeRoles.push(player.role);
    }

    let prevDayActions = iGameData.history
      .filter(
        (h) =>
          !h.info && h.day == iGameData.day && h.daytime == currentDaytime.id
      )
      .reverse();

    let dayData = {
      killedNow: [],
      putanaLock: null,
      putanaNum: null,

      kamikaze: iGameData.actions.kamikaze,
      martyr: iGameData.actions.martyr,
    };

    for (let prevDayAction of prevDayActions) {
      if ([ROLE_PUTANA].includes(prevDayAction.role)) {
        dayData.putanaNum = prevDayAction.nums[0];
        dayData.putanaLock = prevDayAction.num;
      } else if ([ROLE_CITIZEN].includes(prevDayAction.role)) {
        let killNum = prevDayAction.num;
        killPlayer(prevPlayersData, dayData, killNum);
      } else if (
        [ROLE_MAFIA, ROLE_MANIAC, ROLE_KAMIKAZE].includes(prevDayAction.role)
      ) {
        let killNum = prevDayAction.num;
        killPlayer(prevPlayersData, dayData, killNum);
      } else if ([ROLE_DOCTOR].includes(prevDayAction.role)) {
        let killNum = prevDayAction.num;
        if (dayData.killedNow.includes(killNum)) {
          let kPlayer = prevPlayersData.find((pl) => pl.num == killNum);
          if (kPlayer) {
            if (
              SUCCESS_SELECTS[prevDayAction.role] &&
              SUCCESS_SELECTS[prevDayAction.role].includes(kPlayer.role)
            ) {
              prevDayAction.success = true;
              onSuccessSelect(prevDayAction, prevPlayersData);
            }
            if (dayData.kamikaze && kPlayer.role == ROLE_KAMIKAZE)
              dayData.kamikaze = false;
            if (dayData.martyr && kPlayer.role == ROLE_MARTYR)
              dayData.martyr = false;
            kPlayer.alive = true;
            dayData.killedNow = dayData.killedNow.filter((kn) => kn != killNum);
          }
        }
      } else if ([ROLE_MARTYR].includes(prevDayAction.role)) {
        let martyrNum = parseInt(prevDayAction.nums[0]);

        let killOne = null;
        let ii = martyrNum;
        while (ii != martyrNum - 1) {
          if (ii == prevPlayersData.length) ii = 0;
          if (prevPlayersData[ii].alive) killOne = ii + 1;
          ii++;
          if (killOne) break;
        }
        if (killOne) killPlayer(prevPlayersData, dayData, killOne);

        let killTwo = null;
        let zz = martyrNum - 2;
        while (zz != martyrNum - 1) {
          if (zz < 0) zz = prevPlayersData.length - 1;
          console.log("zz", zz);
          if (prevPlayersData[zz].alive) killTwo = zz + 1;
          zz--;
          if (killTwo) break;
        }
        if (killTwo) killPlayer(prevPlayersData, dayData, killTwo);
      }
    }

    iGameData.actions.kamikaze = dayData.kamikaze;
    iGameData.actions.martyr = dayData.martyr;

    if (iGameData.history.length > 0 && addHistory)
      iGameData.history.unshift({
        day: iGameData.day,
        daytime: currentDaytime.id,
        info: true,
        role: "-",
        text:
          dayData.killedNow.length == 0
            ? "Никто не погибает"
            : dayData.killedNow.length == 1
            ? ` - убит!`
            : ` - убиты!`,
        num: 0,
        nums: dayData.killedNow,
        locked: true,
        logs: true,
      });

    let newDaytime = nextDaytime(
      iGameData,
      iGameData.actions.kamikaze,
      iGameData.actions.martyr
    );

    let typeCounts = getAliveRolesCountByType(prevPlayersData);
    if (
      [
        DAYTIME_TECH_LOSE_CITIZEN.id,
        DAYTIME_TECH_LOSE_CITIZEN_NO_MAFIA.id,
        DAYTIME_TECH_LOSE_CITIZEN_NO_MANIAC.id,
        DAYTIME_TECH_LOSE_MAFIA.id,
        DAYTIME_TECH_LOSE_MAFIA_NO_CITIZEN.id,
        DAYTIME_TECH_LOSE_MAFIA_NO_MANIAC.id,
        DAYTIME_TECH_LOSE_MANIAC.id,
        DAYTIME_TECH_LOSE_MANIAC_NO_CITIZEN.id,
        DAYTIME_TECH_LOSE_MANIAC_NO_MAFIA.id,
      ].includes(currentDaytime.id)
    ) {
      newDaytime = currentDaytime;
      iGameObj.active = true;
    } else if (!iGameData.actions.kamikaze && !iGameData.actions.martyr) {
      if (typeCounts.all <= 3 && newDaytime.id == DAYTIME_NIGHT.id) {
        newDaytime = DAYTIME_DAY;
      }

      if (typeCounts.all == 0) {
        newDaytime = DAYTIME_WIN_NOBODY;
        iGameObj.result = RESULT_WIN_NOBODY;
        iGameObj.active = true;
      } else if (
        (typeCounts.all == 2 && typeCounts.maniac == 1) ||
        typeCounts.all == typeCounts.maniac
      ) {
        newDaytime = DAYTIME_WIN_MANIAC;
        iGameObj.result = RESULT_WIN_MANIAC;
        iGameObj.active = true;
      } else if (typeCounts.all == typeCounts.citizen) {
        newDaytime = DAYTIME_WIN_CITIZEN;
        iGameObj.result = RESULT_WIN_CITIZEN;
        iGameObj.active = true;
      } else if (
        typeCounts.maniac == 0 &&
        typeCounts.citizen <= typeCounts.mafia
      ) {
        newDaytime = DAYTIME_WIN_MAFIA;
        iGameObj.result = RESULT_WIN_MAFIA;
        iGameObj.active = true;
      }
    }

    if (
      [DAYTIME_NIGHT_KAMIKAZE.id, DAYTIME_DAY_KAMIKAZE.id].includes(
        newDaytime.id
      )
    )
      iGameData.actions.kamikaze = false;
    else if (
      [DAYTIME_NIGHT_MARTYR.id, DAYTIME_DAY_MARTYR.id].includes(newDaytime.id)
    )
      iGameData.actions.martyr = false;
    else if (newDaytime.id == DAYTIME_DAY.id) iGameData.day += 1;
    iGameData.daytime = newDaytime;
    let newRoleQueue = QUEUE_MASTER[iGameData.daytime.id] || [
      EVENT_TALK,
      ROLE_CITIZEN,
    ];

    console.log(iGameData.daytime.id, newRoleQueue);

    newRoleQueue = newRoleQueue
      .filter((rq) => activeRoles.includes(rq) || CUSTOM_EVENTS.includes(rq))
      .map((rq) => {
        let turnData = TURNS_DATA[rq];
        let aliveNums = [ROLE_CITIZEN].includes(rq)
          ? getAliveNums(prevPlayersData)
          : getAliveNumsByRole(prevPlayersData, rq);
        let roleAlive =
          [ROLE_KAMIKAZE, ROLE_MARTYR].includes(rq) || aliveNums.length > 0;
        return {
          role: rq,
          nums: [ROLE_KAMIKAZE, ROLE_MARTYR].includes(rq)
            ? getNumsByRole(rq)
            : aliveNums,
          text: roleAlive ? turnData.text : turnData.deadLock,
          select: roleAlive && turnData.select,
          buttonText: turnData.buttonText,
          history: turnData.history && roleAlive,
          locked: !roleAlive || [ROLE_MARTYR].includes(rq),
          button: turnData.button,
        };
      });
    iGameData.roleQueue = newRoleQueue;
    updateDBByResult(prevPlayersData, iGameObj.result);
    // setGameData(iGameObj);
    // onNewStage();
    // forceUpdate();
    // saveGame(iGameObj);
  };

  // useEffect(() => {

  // }, [gameData?.data?.roleQueue]);

  const getActiveUniqueRoles = (lPlayersData) => {
    let activeRoles = [];
    for (let player of lPlayersData) {
      if (![ROLE_MAFIA, ROLE_CITIZEN].includes(player.role))
        activeRoles.push(player.role);
    }
    return activeRoles;
  };

  const getActiveAliveRoles = (lPlayersData) => {
    let activeRoles = [];
    for (let player of lPlayersData) {
      if (player.alive) activeRoles.push(player.role);
    }
    return activeRoles;
  };

  const onChangeSelect = (event) => {
    setSelect(event.target.value);
  };

  const updateGamers = (gameObj) => {
    let newGamers = [];
    let nPlayersData = gameObj.data.playersData;
    for (let player of nPlayersData) {
      if (player.card) {
        newGamers.push({
          card: player.card,
          role: player.role,
          additional_points: player.db,
          penalty: player.penalty ? player.penalty : 0,
          uuid: "",
        });
      }
    }
    gameObj.gamers = newGamers;
  };

  const onPenaltySelectChanged = (event) => {
    setPenalty(event.target.value);
  };

  const onTechLoseSelectChanged = (event) => {
    setTechLose(event.target.value);
  };

  const onKillPlayerSelectChanged = (event) => {
    setKillPlayerModal(event.target.value);
  };

  const onPenaltySet = async () => {
    let playerID = parseInt(penalty) || 0;
    if (playerID > 0) {
      let gameObj = structuredClone(gameData);
      let nPlayersData = gameObj.data.playersData;

      const playerCard = nPlayersData[playerID - 1].card;

      const cardData = playerCard ? await getRatingCard(playerCard) : null;

      let pemaltyPoints = 10;
      if (cardData) {
        if (cardData.penalty_count < 5)
          pemaltyPoints = (cardData.penalty_count + 1) * 10;
        else pemaltyPoints = 50;
      }

      if (cardData) {
        await updatePenaltyCount(playerCard, cardData.penalty_count + 1);
      }

      nPlayersData[playerID - 1].penalty -= pemaltyPoints;

      gameObj.data.history.unshift({
        day: gameObj.data.day,
        daytime: gameObj.data.daytime.id,
        role: "-",
        info: true,
        text: `Игроку выписан штраф за ППК ${pemaltyPoints} баллов`,
        num: playerID,
        nums: [playerID],
        locked: true,
        logs: true,
        cantUndo: true,
      });

      setGameData(gameObj);
      // forceUpdate();
      // saveGame(gameObj);
      handleClosePenaltyDialog();
    }
  };

  const onPenalty50Set = async () => {
    let playerID = parseInt(penalty) || 0;
    if (playerID > 0) {
      let gameObj = structuredClone(gameData);
      let nPlayersData = gameObj.data.playersData;

      nPlayersData[playerID - 1].penalty -= 50;

      gameObj.data.history.unshift({
        day: gameObj.data.day,
        daytime: gameObj.data.daytime.id,
        role: "-",
        info: true,
        text: `Игроку выписан штраф 50 баллов`,
        num: playerID,
        nums: [playerID],
        locked: true,
        logs: true,
      });

      setGameData(gameObj);
      // forceUpdate();
      // saveGame(gameObj);
      handleClosePenalty50Dialog();
    }
  };

  const onTechLoseSet = () => {
    let newGameResult = "";
    let newGameDaytime = null;

    let typeCounts = getAliveRolesCountByType(gameData.data.playersData);

    switch (techLose) {
      case EVENT_TECH_LOSE_CITIZEN: {
        if (typeCounts.mafia > 0 && typeCounts.maniac > 0) {
          newGameResult = RESULT_TECH_LOSE_CITIZEN;
          newGameDaytime = DAYTIME_TECH_LOSE_CITIZEN;
        } else if (typeCounts.mafia > 0) {
          newGameResult = RESULT_TECH_LOSE_CITIZEN_NO_MANIAC;
          newGameDaytime = DAYTIME_TECH_LOSE_CITIZEN_NO_MANIAC;
        } else {
          newGameResult = RESULT_TECH_LOSE_CITIZEN_NO_MAFIA;
          newGameDaytime = DAYTIME_TECH_LOSE_CITIZEN_NO_MAFIA;
        }
        break;
      }
      case EVENT_TECH_LOSE_MAFIA: {
        if (typeCounts.citizen > 0 && typeCounts.maniac > 0) {
          newGameResult = RESULT_TECH_LOSE_MAFIA;
          newGameDaytime = DAYTIME_TECH_LOSE_MAFIA;
        } else if (typeCounts.citizen > 0) {
          newGameResult = RESULT_TECH_LOSE_MAFIA_NO_MANIAC;
          newGameDaytime = DAYTIME_TECH_LOSE_MAFIA_NO_MANIAC;
        } else {
          newGameResult = RESULT_TECH_LOSE_MAFIA_NO_CITIZEN;
          newGameDaytime = DAYTIME_TECH_LOSE_MAFIA_NO_CITIZEN;
        }

        break;
      }
      case EVENT_TECH_LOSE_MANIAC: {
        if (typeCounts.citizen > 0 && typeCounts.mafia > 0) {
          newGameResult = RESULT_TECH_LOSE_MANIAC;
          newGameDaytime = DAYTIME_TECH_LOSE_MANIAC;
        } else if (typeCounts.citizen > 0) {
          newGameResult = RESULT_TECH_LOSE_MANIAC_NO_MAFIA;
          newGameDaytime = DAYTIME_TECH_LOSE_MANIAC_NO_MAFIA;
        } else {
          newGameResult = RESULT_TECH_LOSE_MANIAC_NO_CITIZEN;
          newGameDaytime = DAYTIME_TECH_LOSE_MANIAC_NO_CITIZEN;
        }

        break;
      }

      default:
        return;
    }

    if (newGameResult) {
      let gameObj = structuredClone(gameData);
      let iGameData = gameObj.data;
      gameObj.result = newGameResult;
      iGameData.roleQueue = [];
      iGameData.daytime = newGameDaytime;
      onNewStage(gameObj, false);
      setGameData(gameObj);
      // forceUpdate();
      // saveGame(gameObj);
      handleCloseTechLoseDialog();
    }
  };

  const onKillPlayerSet = () => {
    let gameObj = structuredClone(gameData);
    let iGameData = gameObj.data;
    let nPlayersData = iGameData.playersData;

    let playerID = parseInt(killPlayerModal) || 0;
    if (playerID > 0 && playerID <= nPlayersData.length) {
      nPlayersData[playerID - 1].alive = false;

      gameObj.data.history.unshift({
        day: gameObj.data.day,
        daytime: gameObj.data.daytime.id,
        role: "-",
        info: true,
        text: ` - убит!`,
        num: playerID,
        nums: [playerID],
        locked: true,
        logs: true,
      });

      iGameData.daytime = DAYTIME_NIGHT;
      iGameData.roleQueue = [];

      onNewStage(gameObj, false);
      setGameData(gameObj);
      // forceUpdate();
      // saveGame(gameObj);
      handleCloseKillPlayerDialog();
      setKillPlayerModal("");
    }
  };

  const onRoleChanged = (pID, event) => {
    let gameObj = structuredClone(gameData);
    let nPlayersData = gameObj.data.playersData;
    nPlayersData[pID].role = event.target.value;
    setGameData(gameObj);
    // forceUpdate();
    // saveGame(gameObj);
  };

  const onNotesChanged = (pID, event) => {
    let gameObj = structuredClone(gameData);
    let nPlayersData = gameObj.data.playersData;
    nPlayersData[pID].notes = event.target.value;
    setGameData(gameObj);
    // forceUpdate();
    // saveGame(gameObj);
  };

  const onCardChanged = (pID, event) => {
    let gameObj = structuredClone(gameData);
    let nPlayersData = gameObj.data.playersData;
    nPlayersData[pID].card = event.target.value;

    afterCardChanged(nPlayersData.map((pl) => pl.card));
    setGameData(gameObj);
    // forceUpdate();
    // saveGame(gameObj);
  };

  const afterCardChanged = (cardsList) => {
    if (Array.isArray(cardsList))
      localStorage.setItem(LOCAL_STORAGE_CARDS, JSON.stringify(cardsList));
  };

  const onVoteChanged = (pID, event) => {
    let gameObj = structuredClone(gameData);
    let nPlayersData = gameObj.data.playersData;
    nPlayersData[pID].vote = event.target.value;
    setGameData(gameObj);
    // forceUpdate();
    // saveGame(gameObj);
  };

  const onDBChanged = (pID, event) => {
    let gameObj = structuredClone(gameData);
    let nPlayersData = gameObj.data.playersData;
    nPlayersData[pID].db = parseInt(event.target.value);
    setGameData(gameObj);
    // forceUpdate();
    // saveGame(gameObj);
  };

  const getMaxCountVotes = (lPlayersData) => {
    let votesCount = {};
    let maxCount = 0;
    for (let pl of lPlayersData) {
      let iVote = parseInt(pl.vote) || 0;
      if (maxCount < iVote) maxCount = iVote;
      if (!votesCount[`${iVote}`]) votesCount[`${iVote}`] = 0;
      votesCount[`${iVote}`]++;
    }
    if (maxCount > 0) return votesCount[`${maxCount}`];
    else return 0;
  };

  const getNumsByRole = (role) => {
    let nNums = [];
    for (let player of gameData.data.playersData)
      if (player.role == role) nNums.push(player.num);
    return nNums;
  };

  const getAliveRolesCountByType = (lPlayersData) => {
    let count = {
      mafia: 0,
      citizen: 0,
      maniac: 0,
      all: 0,
    };
    let roles = getActiveAliveRoles(lPlayersData);
    for (let role of roles) {
      if ([ROLE_MAFIA, ROLE_DON].includes(role)) count.mafia++;
      else if ([ROLE_MANIAC].includes(role)) count.maniac++;
      else count.citizen++;
      count.all++;
    }
    return count;
  };

  const onSuccessSelect = (lActionData, lPlayersData) => {
    for (let sNum of lActionData.nums) {
      let lNum = parseInt(sNum);
      if (!isNaN(lNum) && lNum > 0 && lPlayersData.length >= lNum) {
        lNum -= 1;
        if (lPlayersData[lNum].db < 1) lPlayersData[lNum].db++;
        // lPlayersData[lNum].db;
      }
    }
  };

  const undo = () => {
    let newUndoGameData = structuredClone(undoGameData);
    console.log("newUndoGameData", newUndoGameData);
    let newGameData = newUndoGameData.pop();
    if (newGameData) {
      console.log("newGameData", newGameData);
      setSelect("");
      setUndoGameData(newUndoGameData);
      setGameData(newGameData);
      // forceUpdate();
      // saveGame(newGameData);
    }
  };

  const next = () => {
    let iGameObj = structuredClone(gameData);

    let newUndoGameData = structuredClone(undoGameData);
    newUndoGameData.push(structuredClone(iGameObj));
    setUndoGameData(newUndoGameData);

    let iGameData = iGameObj.data;
    let curentRole = iGameData.roleQueue[0];
    if (curentRole.select && !select) return;
    let selectedNum = parseInt(select) || 0;
    let selectedRole =
      selectedNum > 0 ? iGameData.playersData[selectedNum - 1].role : "--";
    let turnData = TURNS_DATA[curentRole.role];
    if (curentRole.history) {
      let successSelect =
        curentRole.role != ROLE_DOCTOR &&
        SUCCESS_SELECTS[curentRole.role] &&
        SUCCESS_SELECTS[curentRole.role].includes(selectedRole);
      let newActionData = {
        day: iGameData.day,
        daytime: iGameData.daytime.id,
        role: curentRole.role,
        text:
          (selectedNum < -2 && turnData.doubleNightLock) ||
          (selectedNum < -1 && turnData.maniacNightLock) ||
          (selectedNum < 0 && turnData.nobodyLock) ||
          curentRole.text,
        num: selectedNum,
        nums: curentRole.nums,
        locked: selectedNum < 0 || curentRole.locked,
        success: successSelect,
        logs: turnData.logs,
      };
      if (successSelect) {
        onSuccessSelect(newActionData, iGameData.playersData);
      }
      iGameData.history.unshift(newActionData);
    }
    let newRoleQueue = iGameData.roleQueue.slice(1);

    if (curentRole.role == ROLE_CITIZEN) {
      if (selectedNum < -2) {
        for (let i = 0; i < getMaxCountVotes(iGameData.playersData); i++)
          newRoleQueue.unshift({
            role: curentRole.role,
            nums: getNumsByRole(curentRole.role),
            text: turnData.text,
            select: turnData.select,
            buttonText: turnData.buttonText,
            history: turnData.history,
            locked: false,
            button: turnData.button,
          });
      } else if (selectedNum < -1) {
        let manTurnData = TURNS_DATA[ROLE_MANIAC];
        newRoleQueue.unshift({
          role: ROLE_MANIAC,
          nums: getNumsByRole(ROLE_MANIAC),
          text: manTurnData.text,
          select: manTurnData.select,
          buttonText: manTurnData.buttonText,
          history: manTurnData.history,
          locked: false,
          button: manTurnData.button,
        });
      }
    } else if (curentRole.role == ROLE_PUTANA) {
      let lockNum = parseInt(select);
      let lockTurns = newRoleQueue.filter((rq) => rq.nums.includes(lockNum));
      for (const lockTurn of lockTurns) {
        if (lockTurn && lockTurn.role != ROLE_CITIZEN) {
          let turnData = TURNS_DATA[lockTurn.role];
          lockTurn.text = turnData.putanaLock;
          lockTurn.locked = true;
          lockTurn.select = false;
        }
      }
    }

    iGameData.roleQueue = newRoleQueue;
    setSelect("");
    onNewStage(iGameObj);
    setGameData(iGameObj);
    // forceUpdate();
    // saveGame(iGameObj);
  };

  const getAllNums = () => {
    return gameData.data.playersData.map((p) => p.num);
  };

  function Alert(props) {
    let lNums = [];
    if (props.showAllNums) lNums = getAliveNums(gameData?.data?.playersData);
    else lNums = props.nums;
    let numsString = "";
    let prevNum = -1;
    let lStreak = 0;
    lNums.map((lNum) => {
      let sNum = parseInt(lNum) || 0;
      if (sNum - 1 == prevNum) {
        lStreak++;
      } else {
        lStreak = 0;
      }
      if (!numsString) numsString += `${sNum}`;
      else {
        if (lStreak < 2) numsString += `,${sNum}`;
        else
          numsString =
            numsString.slice(0, -1 - `${prevNum}`.length) + `-${sNum}`;
      }
      prevNum = sNum;
      return lNum;
    });
    return (
      <MuiAlert
        icon={<div>{<nobr>{numsString}</nobr>}</div>}
        elevation={0}
        classes={{ root: classes.alert, message: classes.alertMessage }}
        {...props}
      />
    );
  }

  const handleClosePenaltyDialog = () => {
    setOpenPenaltyDialog(false);
  };

  const handleClosePenalty50Dialog = () => {
    setOpenPenalty50Dialog(false);
  };

  const handleCloseTechLoseDialog = () => {
    setOpenTechLoseDialog(false);
  };

  const handleCloseKillPlayerDialog = () => {
    setOpenKillPlayerDialog(false);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#424242",
      color: "#fff",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(10),
      border: "1px solid #aaa",
    },
  }));

  const DownloadGameButton = (props) => {
    return (
      <HtmlTooltip
        // placement="right"
        title={
          <React.Fragment>
            <Typography
              component="p"
              variant="subtitle2"
              color="inherit"
              align="center"
            >
              Скачать игру
            </Typography>
            <Typography
              component="p"
              variant="caption"
              color="inherit"
              align="justify"
            >
              Сохраняет игру в виде файла, который позже необходимо загрузить в
              главном меню. Используется при проблемах с интернетом.
            </Typography>
          </React.Fragment>
        }
      >
        <IconButton
          onClick={() => downloadGame()}
          aria-label="Скачать"
          component="span"
          variant="outlined"
          style={{
            marginLeft: 4,
            marginRight: 4,
            marginBottom: 8,
            padding: 6,
          }}
        >
          <CloudDownloadOutlined />
        </IconButton>
      </HtmlTooltip>
    );
  };

  return loading ? (
    <div style={{ margin: "32px auto", width: "fit-content" }}>
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <main className={classes.content}>
      {error && (
        <div className={classes.control}>
          <MuiAlert
            elevation={0}
            classes={{ root: classes.alert, message: classes.alertMessage }}
            severity="error"
            variant="filled"
          >
            Ошибка при сохранении игры
            <Button
              className={classes.alertButton}
              size="small"
              onClick={() => saveGame(gameData)}
            >
              Повторить
            </Button>
          </MuiAlert>
        </div>
      )}
      <Dialog open={openPenaltyDialog} onClose={handleClosePenaltyDialog}>
        <DialogTitle>Штраф за ППК (от 10 до 50 баллов)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Выберите игрока, которому хотите выписать штраф:
          </DialogContentText>
          <Select
            className={classes.select}
            value={penalty}
            onChange={onPenaltySelectChanged}
          >
            {getAllNums(gameData.data.playersData).map((num) => (
              <MenuItem value={num}>{num}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePenaltyDialog}>Отмена</Button>
          <Button onClick={onPenaltySet}>Выписать</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPenalty50Dialog} onClose={handleClosePenaltyDialog}>
        <DialogTitle>Выписать штраф (50 баллов)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Выберите игрока, которому хотите выписать штраф:
          </DialogContentText>
          <Select
            className={classes.select}
            value={penalty}
            onChange={onPenaltySelectChanged}
          >
            {getAllNums(gameData.data.playersData).map((num) => (
              <MenuItem value={num}>{num}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePenalty50Dialog}>Отмена</Button>
          <Button onClick={onPenalty50Set}>Выписать</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openTechLoseDialog} onClose={handleCloseTechLoseDialog}>
        <DialogTitle>Объявить Техническое поражение</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Выберите команду, которая потерпела техническое поражение:
          </DialogContentText>
          <Select
            className={classes.select}
            value={techLose}
            onChange={onTechLoseSelectChanged}
          >
            <MenuItem value={EVENT_TECH_LOSE_CITIZEN}>
              Тех. поражение Мирных
            </MenuItem>
            <MenuItem value={EVENT_TECH_LOSE_MAFIA}>
              Тех. поражение Мафии
            </MenuItem>
            <MenuItem value={EVENT_TECH_LOSE_MANIAC}>
              Тех. поражение Маньяка
            </MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTechLoseDialog}>Отмена</Button>
          <Button onClick={onTechLoseSet}>Завершить</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openKillPlayerDialog} onClose={handleCloseKillPlayerDialog}>
        <DialogTitle>Убить игрока</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Выберите игрока, которого хотите убить:
          </DialogContentText>
          <Select
            className={classes.select}
            value={killPlayerModal}
            onChange={onKillPlayerSelectChanged}
          >
            {getAliveNums(gameData.data.playersData).map((num) => (
              <MenuItem value={num}>{num}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseKillPlayerDialog}>Отмена</Button>
          <Button onClick={onKillPlayerSet}>Убить</Button>
        </DialogActions>
      </Dialog>

      <TableContainer className={classes.container} component={Paper}>
        <Table className={classes.table} size="small" aria-label="Игроки">
          <TableHead>
            <TableRow>
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#d32f2f88" }}
              >
                №
              </TableCell>
              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                    style={{ background: "#d32f2f44" }}
                    // style={{ color: "#d32f2f" }}
                  >
                    {n}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="1">
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#d32f2f77" }}
                // style={{ background: "#4caf5088" }}
              >
                Жив
              </TableCell>
              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                    style={{ background: "#d32f2f33" }}
                  >
                    <Checkbox
                      disabled
                      style={{
                        padding: 0,
                        color: gameData.data.playersData[n - 1].alive
                          ? "#6fbf73"
                          : "#ffffff22",
                      }}
                      checked={gameData.data.playersData[n - 1].alive}
                    />
                  </TableCell>
                ))}
            </TableRow>
            <TableRow key="2">
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#00968866" }}
              >
                Голоса
                <br />
                (ост:
                {getAliveNums(gameData.data.playersData).length -
                  gameData.data.playersData
                    .map((pl) => parseInt(pl.vote) || 0)
                    .reduce((v1, v2) => v1 + v2)}
                )
              </TableCell>
              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                  >
                    <Input
                      className={classes.input}
                      classes={{ input: classes.cardnum }}
                      type="number"
                      value={gameData.data.playersData[n - 1].vote || ""}
                      onChange={(e) => onVoteChanged(n - 1, e)}
                    />
                  </TableCell>
                ))}
            </TableRow>
            <TableRow key="3">
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#b2890066" }}
              >
                Роль
              </TableCell>
              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                  >
                    <Select
                      className={classes.select}
                      value={gameData.data.playersData[n - 1].role}
                      onChange={(e) => onRoleChanged(n - 1, e)}
                    >
                      {Object.keys(ROLES_LIST).map((roleID) =>
                        !getActiveUniqueRoles(
                          gameData.data.playersData
                        ).includes(roleID) ? (
                          <MenuItem value={roleID}>
                            {ROLES_LIST[roleID]}
                          </MenuItem>
                        ) : (
                          <MenuItem disabled value={roleID}>
                            {ROLES_LIST[roleID]}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </TableCell>
                ))}
            </TableRow>
            <TableRow key="5">
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#1769aa66" }}
              >
                Карта
              </TableCell>
              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                  >
                    <Input
                      className={classes.input}
                      classes={{ input: classes.cardnum }}
                      type="number"
                      value={gameData.data.playersData[n - 1].card}
                      onChange={(e) => onCardChanged(n - 1, e)}
                    />
                  </TableCell>
                ))}
            </TableRow>
            <TableRow key="4">
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#d32f2f88" }}
              >
                Замечания
              </TableCell>
              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                    style={{ background: "#d32f2f44" }}
                  >
                    <Input
                      className={classes.input}
                      value={gameData.data.playersData[n - 1].notes}
                      onChange={(e) => onNotesChanged(n - 1, e)}
                    />
                  </TableCell>
                ))}
            </TableRow>
            <TableRow key="6">
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#d32f2f77" }}
                // style={{background: "#9c27b088"}}
              >
                ДБ
              </TableCell>

              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                    style={{ background: "#d32f2f33" }}
                    // style={{background: "#9c27b044"}}
                  >
                    <Input
                      className={classes.input}
                      classes={{ input: classes.cardnum }}
                      type="number"
                      value={gameData.data.playersData[n - 1].db || ""}
                      onChange={(e) => onDBChanged(n - 1, e)}
                    />
                  </TableCell>
                ))}
            </TableRow>
            <TableRow key="7">
              <TableCell
                classes={{ sizeSmall: classes.sizeSmall }}
                align="center"
                style={{ background: "#d32f2f66" }}
                // style={{background: "#aa2e2588"}}
              >
                Штраф
              </TableCell>

              {gameData.data.playersData.length &&
                getAllNums().map((n) => (
                  <TableCell
                    classes={{ sizeSmall: classes.sizeSmall }}
                    align="center"
                    style={{ background: "#d32f2f22" }}
                    // style={{background: "#aa2e2544"}}
                  >
                    {gameData.data.playersData[n - 1].penalty || ""}
                  </TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.controlButtons}>
        <div style={{ marginBottom: 8 }}>
          {!gameData.result &&
          gameData?.data?.daytime?.id != DAYTIME_START.id ? (
            <>
              {/* <Button
								variant="outlined"
								style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
								onClick={() => undo()}
							>
								Отмена
							</Button> */}
              <Button
                variant="outlined"
                style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
                onClick={() => setOpenPenaltyDialog(true)}
              >
                Штраф ППК
              </Button>
              <Button
                variant="outlined"
                style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
                onClick={() => setOpenPenalty50Dialog(true)}
              >
                Штраф -50
              </Button>
              {gameData?.data?.daytime?.id == DAYTIME_DAY.id && (
                <Button
                  variant="outlined"
                  style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
                  onClick={() => setOpenKillPlayerDialog(true)}
                >
                  Убить игрока
                </Button>
              )}
              <Button
                variant="outlined"
                style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
                onClick={() => setOpenTechLoseDialog(true)}
              >
                Техническое поражение
              </Button>
              {/* <Button
								variant="outlined"
								style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
								onClick={() => downloadGame()}
							>
								Скачать
							</Button> */}
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      color="inherit"
                      align="center"
                    >
                      Отмена хода
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="inherit"
                      align="justify"
                    >
                      Отменяет последнее действие ведущего. Может использоваться
                      неограниченное количество раз.
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => undo()}
                  aria-label="Отмена"
                  component="span"
                  variant="outlined"
                  disabled={
                    gameData.data.history.length > 0 &&
                    !!gameData.data.history[0].cantUndo
                  }
                  style={{
                    marginLeft: 4,
                    marginRight: 4,
                    marginBottom: 8,
                    padding: 6,
                  }}
                >
                  <HistoryRounded />
                </IconButton>
              </HtmlTooltip>
              <DownloadGameButton />
            </>
          ) : (
            <>
              {gameData.result && (
                <Button
                  variant="outlined"
                  onClick={() => newGame()}
                  style={{ marginLeft: 4, marginRight: 4, marginBottom: 4 }}
                >
                  Новая игра
                </Button>
              )}
              {gameData?.data?.daytime?.id != DAYTIME_START.id && (
                <DownloadGameButton />
              )}
            </>
          )}
        </div>
      </div>
      <div className={classes.control}>
        {/* <Typography variant="h6" gutterBottom>День {gameData?.data?.day} ({gameData?.data?.daytime.name})</Typography> */}
        <div>
          {gameData.data.roleQueue.length > 0 ? (
            <Alert
              severity="success"
              variant="filled"
              nums={gameData.data.roleQueue[0].nums}
              // showAllNums={gameData.data.roleQueue[0].role == ROLE_CITIZEN}
            >
              {gameData.data.roleQueue[0].text}
              {gameData.data.roleQueue[0].select ? (
                <Select
                  classes={{ root: classes.alertSelect }}
                  value={select}
                  onChange={onChangeSelect}
                >
                  {gameData.data.roleQueue[0].role == ROLE_CITIZEN ? (
                    getAliveNumsByRole(gameData.data.playersData, ROLE_MANIAC)
                      .length > 0 ? (
                      <MenuItem value={-2}>Ночь маньяка</MenuItem>
                    ) : getMaxCountVotes(gameData.data.playersData) > 1 ? (
                      <MenuItem value={-3}>
                        Убить несколько (
                        {getMaxCountVotes(gameData.data.playersData)})
                      </MenuItem>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <MenuItem value={-1}>-</MenuItem>
                  {[ROLE_DON, ROLE_COMMISSAR].includes(
                    gameData.data.roleQueue[0].role
                  )
                    ? getAllNums(gameData.data.playersData).map((num) => (
                        <MenuItem value={num}>{num}</MenuItem>
                      ))
                    : getAliveNums(gameData.data.playersData).map((num) => (
                        <MenuItem value={num}>{num}</MenuItem>
                      ))}
                </Select>
              ) : (
                ""
              )}
              {gameData.data.roleQueue[0].button ? (
                <Button
                  className={classes.alertButton}
                  size="small"
                  onClick={next}
                >
                  {gameData.data.roleQueue[0].buttonText}
                </Button>
              ) : (
                <></>
              )}
            </Alert>
          ) : (
            ""
          )}
          {gameData.data.history.length > 0
            ? gameData.data.history.map((hData) => (
                <Alert
                  severity={hData.info ? "error" : "success"}
                  variant="outlined"
                  nums={hData.nums}
                  // showAllNums={hData.role == ROLE_CITIZEN}
                >
                  {hData.text}
                  {!hData.locked && hData.num}
                  {hData.success ? ` (+1 ДБ)` : ""}
                </Alert>
              ))
            : ""}
        </div>
      </div>
    </main>
  );
}

export default Game;
