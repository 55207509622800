import React, { useState } from "react";
import {
  Button,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  authContainer: {
    marginTop: "calc(55vh - 180px)",
  },
  authForm: {
    display: "grid",
    justifyItems: "center",
    "& .MuiTextField-root": {
      width: "250px",
      margin: theme.spacing(1),
    },
    "&>.MuiInputBase-root": {
      width: "250px",
      margin: theme.spacing(1),
    },
    "& .MuiButton-root": {
      width: "250px",
      margin: theme.spacing(1),
      border: "1px solid #fff",
    },
  },
  error: {
    color: "#ff0000",
  },
}));

function Auth({ error, onAuth, onSendCode, step, onClearStep }) {
  const classes = useStyles();

  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("whatsapp");

  const handlePhoneChange = (event) => {
    setPhone(event.target.value.replace(/[^+\d]/g, ""));
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value.replace(/[^\d]/g, ""));
  };

  const handleTypeChange = (event) => {
    console.log(event.target.value);
    setType(event.target.value);
    onClearStep();
  };

  const handleAuth = () => {
    onAuth(phone, type, code);
  };

  const handleSendCode = () => {
    onSendCode(phone, type);
  };

  return (
    <div className={classes.authContainer}>
      <form
        className={classes.authForm}
        noValidate
        // autoComplete="off"
      >
        <TextField
          id="phone"
          type="tel"
          label="Телефон"
          variant="outlined"
          error={!!error}
          value={phone}
          onChange={handlePhoneChange}
        />
        <Select variant="outlined" value={type} onChange={handleTypeChange}>
          <MenuItem value="whatsapp">WhatsApp</MenuItem>
          <MenuItem value="telegram">Telegram</MenuItem>
        </Select>
		{step == 0 && (
          <>
            <Button onClick={handleSendCode}>Отправить код</Button>
          </>
        )}
        {step == 1 && (
          <>
            <TextField
              id="code"
              label="Код"
              variant="outlined"
              autoComplete="off"
              error={!!error}
              value={code}
              onChange={handleCodeChange}
            />
            <Button onClick={handleAuth}>Войти</Button>
          </>
        )}
        <div className={classes.error}>{error}</div>
      </form>
    </div>
  );
}

export default Auth;
