import axios from "axios";

export const coreApi = axios.create({
  // baseURL: "http://127.0.0.1:9991",
  baseURL: "https://core.mafdrive.ru",
  headers: {
    "Content-Type": "application/json",
  },
});

coreApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem("__md_token");
  if (token) {
    config.headers["authorization"] = token;
    config.headers["authorization-type"] = "token";
  }

  return config;
});

export const coreApiFetcher = (url, config) =>
  coreApi
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err.response.data;
    });
