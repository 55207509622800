import { coreApi } from "../../core";

export const getMe = () =>
  coreApi
    .get("/admin-rating/user")
    .then((res) => res.data)
    .catch((data) => {
      return data.message.includes("403")
        ? {
            success: false,
            errors: { server: "У вас нет прав на ведение игр" },
          }
        : { success: false };
    });

export const getClubs = () =>
  coreApi
    .get("/admin-rating/user/clubs")
    .then((res) => res.data)
    .catch(() => ({ success: false }));

export const getPlaygrounds = (payload) =>
  coreApi
    .post("/admin-rating/utils/playgrounds", payload)
    .then((res) => res.data)
    .catch(() => ({ success: false }));
