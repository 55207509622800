import { SvgIcon } from "@material-ui/core";

function WinIcon(props) {
	return (
		<SvgIcon viewBox="0 0 512 512" {...props}>
			<g id="XMLID_890_">
		<path id="XMLID_1021_" d="m352 334h150v168h-150z" fill="#cff1fb" />
		<path id="XMLID_1019_" d="m10 334h150v168h-150z" fill="#cff1fb" transform="matrix(-1 0 0 -1 170 836)" />
		<path id="XMLID_1018_" d="m160 258h192v244h-192z" fill="#91def5" />
		<path id="XMLID_1888_" d="m256 180.077-49.128 35.858c-8.225 6.003-19.318-2.056-16.15-11.734l18.922-57.804-49.383-35.714c-8.242-5.961-4.025-18.986 6.146-18.986h60.943l18.671-57.76c3.13-9.682 16.829-9.682 19.958 0l18.671 57.76h60.943c10.171 0 14.388 13.025 6.146 18.986l-49.383 35.714 18.922 57.804c3.168 9.678-7.925 17.737-16.15 11.734z" fill="#ffcb7c" />
		<g fill="#444" id="XMLID_1168_">
			<path id="XMLID_1308_" d="m146.915 95.876c-2.788 8.613.15 17.605 7.485 22.91l43.469 31.438-16.651 50.867c-2.819 8.613.097 17.621 7.428 22.948 7.331 5.326 16.799 5.317 24.122-.026l43.232-31.555 43.233 31.556c3.666 2.676 7.871 4.014 12.077 4.014 4.191 0 8.384-1.329 12.044-3.988 7.331-5.327 10.247-14.335 7.428-22.948l-16.651-50.867 43.469-31.437c7.335-5.306 10.273-14.298 7.485-22.911-2.788-8.612-10.439-14.178-19.492-14.178h-53.665l-16.433-50.836c-2.786-8.617-10.438-14.186-19.495-14.186s-16.709 5.568-19.495 14.186l-16.433 50.836h-53.665c-9.053-.001-16.704 5.564-19.492 14.177zm80.435 5.822c4.338 0 8.181-2.797 9.516-6.924l18.671-57.76c.054-.169.105-.248.118-.262.127-.101.565-.102.69-.001.014.015.065.094.119.263l18.671 57.76c1.335 4.127 5.178 6.924 9.516 6.924h60.942c.177 0 .269.024.285.032.136.089.271.506.213.655-.009.018-.069.092-.212.194l-49.383 35.715c-3.523 2.548-4.996 7.081-3.644 11.214l18.922 57.805c.055.168.061.263.058.28-.041.156-.354.44-.557.406-.021-.004-.109-.038-.252-.142l-49.128-35.857c-1.756-1.282-3.826-1.923-5.896-1.923s-4.14.641-5.896 1.923l-49.127 35.856c-.144.104-.232.139-.251.143-.118-.021-.517-.249-.559-.404-.003-.02.003-.114.058-.282l18.922-57.805c1.353-4.133-.12-8.666-3.644-11.214l-49.383-35.715c-.143-.103-.203-.177-.212-.192-.058-.151.077-.568.212-.656.018-.009.109-.033.286-.033z" />
			<path id="XMLID_1315_" d="m265.404 326h-18.809c-5.522 0-10 4.478-10 10s4.478 10 10 10h8.809v78c0 5.522 4.478 10 10 10s10-4.478 10-10v-88c0-5.523-4.477-10-10-10z" />
			<path id="XMLID_1318_" d="m322.5 53.352c2.56 0 5.118-.977 7.071-2.929l33.353-33.353c3.905-3.905 3.905-10.237 0-14.143-3.906-3.904-10.236-3.904-14.143 0l-33.353 33.353c-3.905 3.905-3.905 10.237 0 14.143 1.954 1.953 4.512 2.929 7.072 2.929z" />
			<path id="XMLID_1323_" d="m181.429 50.424c1.953 1.952 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929c3.905-3.905 3.905-10.237 0-14.143l-33.352-33.353c-3.906-3.904-10.236-3.904-14.143 0-3.905 3.905-3.905 10.237 0 14.143z" />
			<path id="XMLID_1325_" d="m350.613 161.904c-2.386 4.98-.283 10.952 4.698 13.339l36.82 17.641c1.394.668 2.864.983 4.313.983 3.729 0 7.307-2.095 9.025-5.682 2.386-4.98.283-10.952-4.698-13.339l-36.82-17.641c-4.977-2.385-10.951-.282-13.338 4.699z" />
			<path id="XMLID_1326_" d="m115.555 193.867c1.448 0 2.92-.316 4.313-.983l36.82-17.641c4.981-2.387 7.084-8.358 4.698-13.339-2.387-4.982-8.358-7.084-13.339-4.698l-36.82 17.641c-4.981 2.387-7.084 8.358-4.698 13.339 1.719 3.587 5.297 5.681 9.026 5.681z" />
			<path id="XMLID_1330_" d="m502 324h-140v-56h15c5.522 0 10-4.478 10-10s-4.478-10-10-10h-242c-5.522 0-10 4.478-10 10s4.478 10 10 10h15v56h-140c-5.522 0-10 4.478-10 10v168c0 5.522 4.478 10 10 10h492c5.522 0 10-4.478 10-10v-168c0-5.523-4.478-10-10-10zm-482 20h130v148h-130zm472 148h-130v-29c0-5.522-4.478-10-10-10s-10 4.478-10 10v29h-172v-224h172v105c0 5.522 4.478 10 10 10s10-4.478 10-10v-29h130z" />
			<path id="XMLID_1333_" d="m352 408c-2.63 0-5.21 1.069-7.07 2.93s-2.93 4.44-2.93 7.07 1.069 5.21 2.93 7.069c1.86 1.86 4.44 2.931 7.07 2.931s5.21-1.07 7.069-2.931c1.861-1.859 2.931-4.439 2.931-7.069s-1.07-5.21-2.931-7.07c-1.859-1.861-4.439-2.93-7.069-2.93z" />
		</g>
	</g>
		</SvgIcon>
	);
}

export default WinIcon;
