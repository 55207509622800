import { SvgIcon } from "@material-ui/core";

function AmazedIcon(props) {
	return (
		<SvgIcon viewBox="0 0 60 60" {...props}>
			<g id="Page-1" fill="none" fill-rule="evenodd">
				<g id="047---Amazed" fill-rule="nonzero">
					<path
						id="Shape"
						d="m59 34c0 13.81-13 25-29 25s-29-11.19-29-25c.05593748-3.390638.69527761-6.746328 1.89-9.92.80804842 1.1456001 1.93462722 2.0287822 3.24 2.54 4.63 1.81 10.32-1.69 12.7-7.81 2-5.21 1-10.64-2.22-13.25 3.8838866-2.86905512 8.5623185-4.46230749 13.39-4.56 4.8448283.09748689 9.5389566 1.70180924 13.43 4.59-3.25 2.59-4.29 8-2.26 13.28 2.38 6.11 8.07 9.61 12.7 7.81 1.3144109-.5148088 2.4482007-1.4051468 3.26-2.56 1.1790422 3.1634759 1.811394 6.5044577 1.87 9.88z"
						fill="#ef314c"
					/>
					<path
						id="Shape"
						d="m57.13 24.12c-.6139431.8607947-1.404989 1.5802373-2.32 2.11.7555486 2.5221756 1.1560642 5.1373071 1.19 7.77 0 13.38-12.18 24.29-27.5 25h1.5c16 0 29-11.19 29-25-.058606-3.3755423-.6909578-6.7165241-1.87-9.88z"
						fill="#bb193b"
					/>
					<path
						id="Shape"
						d="m40.43 5.59c-3.25 2.59-4.29 8-2.26 13.28 2.2 5.65 7.23 9.06 11.64 8.12-3.51-.7-6.93-3.73-8.64-8.12-2-5.24-1-10.69 2.26-13.28-3.8910434-2.88819076-8.5851717-4.49251311-13.43-4.59-.5 0-1 0-1.5.07 4.3141318.39192522 8.4392567 1.95483928 11.93 4.52z"
						fill="#bb193b"
					/>
					<path
						id="Shape"
						d="m18.83 18.81c-2.38 6.12-8.07 9.62-12.7 7.81-1.30537278-.5112178-2.43195158-1.3943999-3.24-2.54-2.1-2.88-2.55-7.42-.89-11.79 2.38-6.12 8.07-9.62 12.7-7.82.675309.26576784 1.3059482.63335971 1.87 1.09 3.26 2.61 4.28 8.04 2.26 13.25z"
						fill="#f5f5f5"
					/>
					<path
						id="Shape"
						d="m16.61 5.56c-.5640518-.45664029-1.194691-.82423216-1.87-1.09-1.2953151-.49182763-2.7050295-.59946592-4.06-.31.3612186.07416243.7157418.17784373 1.06.31.675309.26576784 1.3059482.63335971 1.87 1.09 3.22 2.61 4.24 8 2.22 13.25-1.71 4.39-5.12 7.42-8.63 8.12 4.41.94 9.43-2.46 11.63-8.12 2.02-5.21 1-10.64-2.22-13.25z"
						fill="#cfd8dc"
					/>
					<path
						id="Shape"
						d="m57.13 24.11c-.8117993 1.1548532-1.9455891 2.0451912-3.26 2.56-4.63 1.8-10.32-1.7-12.7-7.81-2-5.24-1-10.69 2.26-13.28.5498442-.44826463 1.167584-.80608113 1.83-1.06 4.63-1.8 10.32 1.69 12.7 7.81 1.7 4.38 1.25 8.89-.83 11.78z"
						fill="#f5f5f5"
					/>
					<path
						id="Shape"
						d="m58 12.34c-2.2-5.66-7.23-9.06-11.64-8.12 3.51.7 6.93 3.72 8.64 8.12s1.25 8.88-.83 11.77c-.8117993 1.1548532-1.9455891 2.0451912-3.26 2.56-.3442582.1321563-.6987814.2358376-1.06.31 2.7960122.6134122 5.6859965-.5196827 7.32-2.87 2.04-2.89 2.49-7.4.83-11.77z"
						fill="#cfd8dc"
					/>
					<ellipse
						id="Oval"
						cx="10.17"
						cy="14.859"
						fill="#a4c400"
						rx="6"
						ry="5"
						transform="matrix(.401 -.916 .916 .401 -7.518 18.227)"
					/>
					<path
						id="Shape"
						d="m12.58 9.35c-1.0342597-.43398131-2.1933079-.46607803-3.25-.09l.25.09c2.53 1.11 3.5 4.47 2.18 7.51-.6996232 1.6652041-2.0625042 2.9628409-3.76 3.58 2.48.91 5.45-.65 6.74-3.58s.37-6.4-2.16-7.51z"
						fill="#90ac00"
					/>
					<ellipse
						id="Oval"
						cx="49.826"
						cy="14.85"
						fill="#a4c400"
						rx="5"
						ry="6"
						transform="matrix(.916 -.401 .401 .916 -1.777 21.199)"
					/>
					<path
						id="Shape"
						d="m54.41 12.85c-1.29-2.94-4.26-4.49-6.74-3.59 1.6886271.62933619 3.042108 1.9285331 3.74 3.59 1.32 3 .35 6.39-2.18 7.5-.0816791.0344104-.1651302.0644528-.25.09 1.0566921.376078 2.2157403.3439813 3.25-.09 2.53-1.11 3.5-4.46 2.18-7.5z"
						fill="#90ac00"
					/>
					<path
						id="Shape"
						d="m43.92 34.71c-.1048802.7934284-.4544185 1.5344496-1 2.12-.3423214.353824-.7590482.6270874-1.22.8l-3.22-3.9c-.3945524-.4613333-.9772777-.718502-1.5840082-.6990555s-1.1717915.3134032-1.5359918.7990555l-1.8 2.5c-1.13629-.4281469-2.3465709-.6253274-3.56-.58-1.2134291-.0453274-2.42371.1518531-3.56.58l-1.8-2.5c-.3642003-.4856523-.9292613-.779609-1.5359918-.7990555s-1.1894558.2377222-1.5840082.6990555l-3.22 3.9c-.4609518-.1729126-.8776786-.446176-1.22-.8-.5455815-.5855504-.8951198-1.3265716-1-2.12-.1973833-2.0875332.2604988-4.1847032 1.31-6 2.4299228-4.7246689 7.2970893-7.6946087 12.61-7.6946087s10.1800772 2.9699398 12.61 7.6946087c1.0495012 1.8152968 1.5073833 3.9124668 1.31 6z"
						fill="#37474f"
					/>
					<path
						id="Shape"
						d="m26.44 36.33c-1.9994595 1.0189475-4.1973421 1.5888953-6.44 1.67-.5839824-.014445-1.1602909-.1364868-1.7-.36l3.22-3.9c.3945524-.4613333.9772777-.718502 1.5840082-.6990555s1.1717915.3134032 1.5359918.7990555z"
						fill="#ffeb3a"
					/>
					<path
						id="Shape"
						d="m41.7 37.63c-.5390544.226944-1.1153866.352381-1.7.37-2.2268105-.0775439-4.4103201-.6370683-6.4-1.64l1.8-2.5c.3642003-.4856523.9292613-.779609 1.5359918-.7990555s1.1894558.2377222 1.5840082.6990555z"
						fill="#ffeb3a"
					/>
					<g fill="#000">
						<path
							id="Shape"
							d="m58.89 12c-1.115827-3.0336019-3.181723-5.62561743-5.89-7.39-2.3799895-1.60214672-5.3812315-1.97358757-8.08-1-.5241655.20369803-1.023932.46532079-1.49.78-3.9394707-2.76836136-8.6159985-4.29702533-13.43-4.39-4.7890549.09428908-9.4423104 1.60825029-13.37 4.35-.4779741-.32658245-.9912086-.59829484-1.53-.81-5.13-2-11.41 1.76-14 8.38-1.73 4.48-1.33 9.08.69 12.29-1.13200878 3.1424154-1.7368268 6.4503307-1.79 9.79 0 14.34 13.46 26 30 26s30-11.66 30-26c-.0535001-3.3216039-.6514368-6.6119446-1.77-9.74.5609557-.8873693.9919943-1.8504713 1.28-2.86.8124532-3.1182049.5949708-6.4155188-.62-9.4zm-13.27-6.54c.7017118-.27138828 1.4476365-.41040153 2.2-.41 1.4612718.03395693 2.8843013.47367649 4.11 1.27 2.3301106 1.52831405 4.1074687 3.7649106 5.07 6.38 1.0542485 2.5870065 1.247106 5.4448039.55 8.15-.5023504 2.1865894-2.012712 4.0049608-4.07 4.9-2.1206722.7304868-4.4621629.4113613-6.31-.86-2.3426545-1.5239453-4.128493-3.7658918-5.09-6.39-1.064647-2.5842647-1.2611719-5.444403-.56-8.15.5009965-2.19437575 2.0266307-4.01397362 4.1-4.89zm-42.62 7.19c1.77-4.65 5.59-7.65 9.18-7.65.7525564-.00251217 1.4988686.13657327 2.2.41 4.11 1.6 5.69 7.45 3.51 13-.9659289 2.621621-2.7507585 4.862301-5.09 6.39-1.8489195 1.2695248-4.19105383 1.5850263-6.31.85-4.11-1.56-5.69-7.41-3.49-13zm27 45.35c-15.44 0-28-10.77-28-24 .0358314-2.7162377.4739216-5.4121774 1.3-8 .71924809.6590255 1.55296498 1.1809458 2.46 1.54.93537537.3578443 1.92851169.5408797 2.93.54 1.8315975-.0300705 3.618522-.5703035 5.16-1.56 2.7123733-1.7560024 4.7852123-4.3409134 5.91-7.37 2-5.06 1.2-10.35-1.57-13.45 7.68-4.92 16-4.91 23.64 0-1.0430184 1.17027069-1.7996102 2.56705552-2.21 4.08-.8169023 3.1244791-.5993716 6.4295432.62 9.42 1.1209493 3.0286284 3.19492 5.6114413 5.91 7.36 1.5406535.9927375 3.3275118 1.5364133 5.16 1.57 1.0025007.0018693 1.9964214-.1847028 2.93-.55.9134116-.3583022 1.7538259-.8801724 2.48-1.54.8135668 2.5764773 1.2448234 5.2583545 1.28 7.96 0 13.23-12.56 24-28 24z"
						/>
						<path
							id="Shape"
							d="m7.37 21.27c.66365736.2840667 1.37810332.430358 2.1.43 2.7578454-.1380985 5.1811866-1.8735235 6.2-4.44.7337439-1.620019.8753127-3.4462557.4-5.16-.4066923-1.6343495-1.548734-2.9874715-3.0915375-3.66293751-1.5428035-.67546602-3.31170451-.59680499-4.7884625.21293751-1.57679479.81930161-2.81818485 2.1632567-3.51 3.8-1.55 3.55-.34 7.49 2.69 8.82zm-.86-8c.50640468-1.205383 1.41449628-2.198277 2.57-2.81.55035449-.2874221 1.1592986-.4447897 1.78-.46.4538292-.00116533.9030787.0907266 1.32.27.9966365.4494799 1.7332517 1.329735 2 2.39.3443095 1.263936.2346147 2.6085815-.31 3.8-1.11 2.52-3.65 3.86-5.67 3s-2.79-3.68-1.69-6.21z"
						/>
						<path
							id="Shape"
							d="m8.9 16.6c.1393223.0713839.29345775.1090614.45.11.37878551-.0030355.7233412-.2198346.89-.56l1-2c.2469243-.4916536.050313-1.0904244-.44-1.34-.2384026-.1210697-.5153611-.1416226-.7690229-.0570687-.25366178.0845539-.46289688.2671714-.5809771.5070687l-1 2c-.24548361.4943143-.04408087 1.0940469.45 1.34z"
						/>
						<path
							id="Shape"
							d="m44.33 17.26c1.0188134 2.5664765 3.4421546 4.3019015 6.2 4.44.7218967.000358 1.4363426-.1459333 2.1-.43 3-1.33 4.24-5.28 2.69-8.82-.6918152-1.6367433-1.9332052-2.98069839-3.51-3.8-1.476758-.8097425-3.245659-.88840353-4.7884625-.21293751-1.5428035.67546601-2.6848452 2.02858801-3.0915375 3.66293751-.4753127 1.7137443-.3337439 3.539981.4 5.16zm1.52-4.6c.2707539-1.058217 1.0060855-1.9369383 2-2.39.407636-.1753026.8462774-.2671113 1.29-.27.6191227.0090144 1.2279815.1595188 1.78.44 1.1555037.611723 2.0635953 1.604617 2.57 2.81 1.1 2.53.36 5.3-1.66 6.18s-4.57-.45-5.67-3c-.5374836-1.1826705-.6470728-2.5154163-.31-3.77z"
						/>
						<path
							id="Shape"
							d="m49.76 16.15c.1666588.3401654.5112145.5569645.89.56.1565423-.0009386.3106777-.0386161.45-.11.4940809-.2459531.6954836-.8456857.45-1.34l-1-2c-.1180802-.2398973-.3273153-.4225148-.5809771-.5070687s-.5306203-.064001-.7690229.0570687c-.490313.2495756-.6869243.8483464-.44 1.34z"
						/>
						<path
							id="Shape"
							d="m30 20c-5.6807825.0274136-10.8787072 3.2000729-13.5 8.24-1.1639907 2.003478-1.6579719 4.3262404-1.41 6.63.1464214.9941399.5889905 1.9210958 1.27 2.66.9740762.947671 2.2810023 1.4754681 3.64 1.47 2.49.11 4.89-.91 6.83-1.72 1.0091109-.3922922 2.0881615-.5727013 3.17-.53 1.0944994-.0514381 2.1877145.125656 3.21.52 2.0297229.9946263 4.2345059 1.582342 6.49 1.73h.3c1.3437573-.0022719 2.6342559-.5256408 3.6-1.46.6810095-.7389042 1.1235786-1.6658601 1.27-2.66.2479719-2.3037596-.2460093-4.626522-1.41-6.63-2.6118883-5.0311531-7.7914453-8.2058444-13.46-8.25zm-9.88 17 2.17-2.62c.1906301-.2349915.4774116-.3710289.78-.37.3040712.0169693.5838374.171577.76.42l1.06 1.47c-1.5037023.6722855-3.1237438 1.04588-4.77 1.1zm15-1.1 1.06-1.47c.1761626-.248423.4559288-.4030307.76-.42.3025884-.0010289.5893699.1350085.78.37l2.16 2.62c-1.6477057-.0513298-3.2689162-.4285926-4.77-1.11zm7.82-1.33c-.0372772.7195825-.4067832 1.3809983-1 1.79l-2.69-3.26c-.5785983-.7215902-1.463034-1.1288348-2.3874787-1.0993313-.9244446.0295036-1.7811126.4923153-2.3125213 1.2493313l-1.38 1.9c-1.0320387-.2845531-2.0996247-.4192642-3.17-.4-1.0703753-.0192642-2.1379613.1154469-3.17.4l-1.38-1.91c-.5412216-.7484267-1.3970178-1.205835-2.32-1.24-.9202442-.0293801-1.8011086.374041-2.38 1.09l-2.69 3.26c-.5932168-.4090017-.9627228-1.0704175-1-1.79-.1738002-1.882407.2495522-3.7717486 1.21-5.4 2.2538181-4.3969347 6.7790759-7.1624631 11.72-7.1624631s9.4661819 2.7655284 11.72 7.1624631c.9639264 1.6271105 1.3907945 3.5165266 1.22 5.4z"
						/>
						<path
							id="Shape"
							d="m31 39h-2c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h2c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
}

export default AmazedIcon;
