import { SvgIcon } from "@material-ui/core";

function GodfatherIcon(props) {
	return (
		<SvgIcon viewBox="0 -80 512 512" {...props}>
			<path d="m224 264.070312v24h-24l32 56h-64c-4.398438 0-8-3.597656-8-8v-32c0-22.078124 17.839844-40 40-40zm0 0" fill="#656d78" />
	<path d="m224 288.070312 32 56h-24l-32-56zm0 0" fill="#aab2bd" />
	<path d="m288 288.070312h24l-32 56h-24zm0 0" fill="#aab2bd" />
	<path d="m312 264.070312c22.078125 0 40 17.921876 40 40v32c0 4.402344-3.601562 8-8 8h-64l32-56h-24v-24zm0 0" fill="#656d78" />
	<path d="m256 240.070312c11.519531 0 22.398438-2.71875 32-7.519531v23.527344l-32 16-32-16v-23.527344c9.601562 4.800781 20.480469 7.519531 32 7.519531zm0 0" fill="#f5f7fa" />
	<path d="m256 272.070312-32 16v-32zm0 0" fill="#ed5565" />
	<path d="m288 264.070312v24l-32-16 32-16zm0 0" fill="#ed5565" />
	<path d="m288 288.070312-32 56-32-56 32-16zm0 0" fill="#e6e9ed" />
	<path d="m48 192.070312v56h-32v-32c0-13.277343 10.71875-24 24-24zm0 0" fill="#da4453" />
	<path d="m136 192.070312c13.28125 0 24 10.722657 24 24v32h-32v-56zm0 0" fill="#da4453" />
	<path d="m128 192.070312v56h-80v-56h16l8 16 16-7.917968 16 7.917968 8-16zm0 0" fill="#da4453" />
	<path d="m112 170.632812v21.4375l-8 16-16-7.917968-16 7.917968-8-16v-21.527343c7.28125 3.519531 15.441406 5.449219 24 5.449219s16.640625-1.921876 23.921875-5.449219zm0 0" fill="#ed5565" />
	<path d="m32 80.070312h112l-.800781-3.597656c15.191406 2.878906 24.800781 7.039063 24.800781 11.597656 0 4.480469-9.199219 8.480469-24 11.363282-14.398438 2.878906-34.160156 4.636718-56 4.636718s-41.601562-1.757812-56-4.636718c-14.800781-2.882813-24-6.882813-24-11.363282 0-4.558593 9.519531-8.71875 24.800781-11.597656zm0 0" fill="#656d78" />
	<path d="m144 80.070312h-112l.800781-3.597656 15.199219-68.480468c16 0 24 16.078124 40 16.078124s24-16.078124 40-16.078124l15.199219 68.480468zm0 0" fill="#656d78" />
	<path d="m280 60.222656c28 9.839844 48 36.488282 48 67.839844v8.242188l-24-16.242188s-16.078125 8-44 8-52-8-52-8l-24 16.242188v-8.242188c0-31.359375 20-58 48-67.839844 7.519531-2.71875 15.601562-4.160156 24-4.160156s16.480469 1.449219 24 4.160156zm0 0" fill="#e6e9ed" />
	<g fill="#656d78">
		<path d="m504 88.070312c0 4.480469-9.199219 8.480469-24 11.363282-14.398438 2.878906-34.160156 4.636718-56 4.636718s-41.601562-1.757812-56-4.636718c-14.800781-2.882813-24-6.882813-24-11.363282 0-4.558593 9.519531-8.71875 24.800781-11.597656l-.800781 3.597656h112l-.800781-3.597656c15.199219 2.878906 24.800781 7.039063 24.800781 11.597656zm0 0" />
		<path d="m479.199219 76.472656.800781 3.597656h-112l.800781-3.597656 15.199219-68.480468c16 0 24 16.078124 40 16.078124s24-16.078124 40-16.078124zm0 0" />
		<path d="m472 192.070312c13.28125 0 24 10.722657 24 24v32h-48zm0 0" />
	</g>
	<path d="m472 192.070312-24 56h-24l17.121094-40 6.878906-16zm0 0" fill="#aab2bd" />
	<path d="m376 192.070312 24 56h-48v-32c0-13.277343 10.71875-24 24-24zm0 0" fill="#656d78" />
	<path d="m406.800781 208.070312h.078125l17.121094 40h-24l-24-56h24zm0 0" fill="#aab2bd" />
	<path d="m441.121094 208.070312-17.121094 40-17.121094-40 17.121094-7.917968zm0 0" fill="#e6e9ed" />
	<path d="m448 170.632812v21.4375l-6.878906 16-17.121094-7.917968-17.121094 7.917968h-.078125l-6.800781-16v-21.527343c7.28125 3.519531 15.441406 5.449219 24 5.449219s16.640625-1.921876 23.921875-5.449219zm0 0" fill="#f5f7fa" />
	<path d="m424 104.070312c21.839844 0 41.601562-1.757812 56-4.636718v20.636718c0 22.320313-13.121094 41.601563-32.078125 50.480469-7.28125 3.519531-15.363281 5.449219-23.921875 5.449219s-16.71875-1.921875-24-5.449219c-18.960938-8.871093-32-28.160156-32-50.480469v-20.636718c14.398438 2.878906 34.160156 4.636718 56 4.636718zm0 0" fill="#eac6bb" />
	<path d="m304 120.070312 24 16.242188v31.757812c0 28.242188-16.320312 52.722657-40 64.480469-9.601562 4.808594-20.480469 7.519531-32 7.519531s-22.398438-2.71875-32-7.519531c-23.601562-11.679687-39.839844-36-40-64.078125v-32.160156l24-16.242188s24.078125 8 52 8 44-8 44-8zm0 0" fill="#eac6bb" />
	<path d="m144 120.070312c0 22.320313-13.121094 41.601563-32.078125 50.480469-7.28125 3.519531-15.363281 5.449219-23.921875 5.449219s-16.71875-1.921875-24-5.449219c-18.960938-8.871093-32-28.160156-32-50.480469v-20.636718c14.398438 2.878906 34.160156 4.636718 56 4.636718s41.601562-1.757812 56-4.636718zm0 0" fill="#eac6bb" />
	<path fill="#444" d="m160 344.0625c-4.425781 0-8-3.574219-8-8v-32c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v32c0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m168 352.0625c-8.824219 0-16-7.175781-16-16 0-4.246094 3.34375-7.308594 7.585938-7.542969 4.398437-.152343 7.941406 3.328125 8.414062 7.535157 4.414062 0 8 3.585937 8 8 0 4.425781-3.574219 8.007812-8 8.007812zm0 0" />
	<path fill="#444" d="m160 312.0625c-4.425781 0-8-3.574219-8-8 0-26.46875 21.527344-48 48-48 4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8-17.648438 0-32 14.351562-32 32 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m224 272.0625h-24c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8h24c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m352 312.0625c-4.425781 0-8-3.574219-8-8 0-17.648438-14.351562-32-32-32-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8 26.472656 0 48 21.53125 48 48 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m312 272.0625h-24c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8h24c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m352 344.0625c-4.425781 0-8-3.574219-8-8v-32c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v32c0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m344 352.0625c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8 0-4.421875 3.574219-8 8-8s8 3.578125 8 8c0 8.816406-7.175781 16-16 16zm0 0" />
	<path fill="#444" d="m344 352.0625h-176c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8h176c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m224 296.046875c-4.425781 0-8-3.574219-8-8v-55.472656c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v55.472656c0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m288 296.046875c-4.425781 0-8-3.574219-8-8v-55.472656c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v55.472656c0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m255.992188 248.0625c-44.113282 0-79.992188-35.878906-79.992188-79.984375 0-4.421875 3.574219-8 8-8s8 3.578125 8 8c0 35.28125 28.703125 63.984375 63.992188 63.984375 35.296874 0 64.007812-28.703125 64.007812-63.984375 0-4.421875 3.574219-8 8-8s8 3.578125 8 8c0 44.105469-35.886719 79.984375-80.007812 79.984375zm0 0" />
	<path fill="#444" d="m328 136.03125c-4.425781 0-8-3.574219-8-8 0-35.277344-28.703125-63.984375-63.992188-63.984375-35.296874 0-64.007812 28.707031-64.007812 63.984375 0 4.425781-3.574219 8-8 8s-8-3.574219-8-8c0-44.101562 35.886719-79.984375 80.007812-79.984375 44.113282 0 79.992188 35.882813 79.992188 79.984375 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m184 176.472656c-4.425781 0-8-3.578125-8-8v-40.410156c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v40.410156c0 4.414063-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m328 176.078125c-4.425781 0-8-3.574219-8-8v-40.015625c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v40.015625c0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m327.992188 144.246094c-1.535157 0-3.097657-.445313-4.472657-1.367188l-24-16.199218c-3.664062-2.480469-4.625-7.449219-2.160156-11.113282 2.464844-3.664062 7.433594-4.613281 11.113281-2.160156l24 16.203125c3.664063 2.480469 4.625 7.445313 2.160156 11.109375-1.542968 2.296875-4.074218 3.527344-6.640624 3.527344zm0 0" />
	<path fill="#444" d="m260 136.046875c-28.894531 0-53.496094-8.0625-54.519531-8.40625-4.183594-1.402344-6.457031-5.921875-5.074219-10.113281 1.394531-4.191406 5.921875-6.472656 10.113281-5.078125.234375.078125 23.265625 7.589843 49.488281 7.589843 25.425782 0 40.320313-7.101562 40.472657-7.175781 3.953125-1.917969 8.75-.296875 10.695312 3.65625 1.945313 3.953125.34375 8.71875-3.589843 10.6875-.746094.359375-18.097657 8.839844-47.585938 8.839844zm0 0" />
	<path fill="#444" d="m184.007812 144.246094c-2.566406 0-5.097656-1.230469-6.640624-3.519532-2.472657-3.664062-1.503907-8.628906 2.160156-11.109374l24-16.203126c3.671875-2.460937 8.632812-1.503906 11.113281 2.160157 2.472656 3.664062 1.503906 8.632812-2.160156 11.113281l-24 16.199219c-1.375.921875-2.9375 1.359375-4.472657 1.359375zm0 0" />
	<path fill="#444" d="m256.007812 96.03125c-4.421874 0-8-3.574219-8-8v-31.984375c0-4.421875 3.578126-8 8-8 4.425782 0 8 3.578125 8 8v31.984375c0 4.425781-3.574218 8-8 8zm0 0" />
	<path fill="#444" d="m288 88.03125c-3.167969 0-6.167969-1.886719-7.425781-5.015625l-8-19.871094c-1.644531-4.097656.335937-8.761719 4.441406-10.410156 4.074219-1.621094 8.753906.320313 10.410156 4.441406l8 19.871094c1.644531 4.097656-.335937 8.761719-4.441406 10.410156-.976563.390625-1.984375.574219-2.984375.574219zm0 0" />
	<path fill="#444" d="m224 88.03125c-1 0-2.007812-.183594-2.984375-.574219-4.105469-1.648437-6.085937-6.3125-4.441406-10.410156l8-19.871094c1.65625-4.113281 6.355469-6.054687 10.410156-4.441406 4.105469 1.648437 6.085937 6.3125 4.441406 10.410156l-8 19.871094c-1.257812 3.128906-4.257812 5.015625-7.425781 5.015625zm0 0" />
	<path fill="#444" d="m264 208.046875h-15.992188c-4.421874 0-8-3.574219-8-8 0-4.421875 3.578126-8 8-8h15.992188c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m272 216.046875c-2.046875 0-4.097656-.78125-5.65625-2.34375l-8-8c-3.128906-3.128906-3.128906-8.183594 0-11.3125 3.128906-3.125 8.183594-3.125 11.3125 0l8 8c3.128906 3.128906 3.128906 8.183594 0 11.3125-1.558594 1.5625-3.609375 2.34375-5.65625 2.34375zm0 0" />
	<path fill="#444" d="m240.007812 216.046875c-2.046874 0-4.097656-.78125-5.65625-2.34375-3.128906-3.128906-3.128906-8.183594 0-11.3125l8-8c3.128907-3.125 8.183594-3.125 11.3125 0 3.128907 3.128906 3.128907 8.183594 0 11.3125l-8 8c-1.558593 1.5625-3.609374 2.34375-5.65625 2.34375zm0 0" />
	<path fill="#444" d="m288 296.046875c-1.199219 0-2.425781-.269531-3.566406-.839844l-64.007813-32c-3.953125-1.976562-5.554687-6.78125-3.578125-10.734375 1.96875-3.953125 6.792969-5.535156 10.738282-3.578125l64.007812 32c3.949219 1.976563 5.550781 6.785157 3.574219 10.738281-1.398438 2.800782-4.230469 4.414063-7.167969 4.414063zm0 0" />
	<path fill="#444" d="m224.007812 296.046875c-2.9375 0-5.761718-1.613281-7.167968-4.421875-1.976563-3.953125-.375-8.761719 3.574218-10.738281l64-32c3.953126-1.957031 8.761719-.375 10.738282 3.578125 1.976562 3.949218.375 8.757812-3.578125 10.734375l-64 32c-1.140625.578125-2.367188.847656-3.566407.847656zm0 0" />
	<path fill="#444" d="m255.992188 352.046875c-1.34375 0-2.710938-.335937-3.960938-1.054687-3.832031-2.191407-5.167969-7.082032-2.976562-10.914063l32-56c2.183593-3.839844 7.066406-5.183594 10.914062-2.972656 3.832031 2.191406 5.167969 7.078125 2.976562 10.910156l-32 56c-1.472656 2.585937-4.167968 4.03125-6.953124 4.03125zm0 0" />
	<path fill="#444" d="m279.992188 352.046875c-1.34375 0-2.710938-.335937-3.960938-1.054687-3.832031-2.191407-5.167969-7.082032-2.976562-10.914063l32-56c2.175781-3.839844 7.074218-5.183594 10.914062-2.972656 3.832031 2.191406 5.167969 7.078125 2.976562 10.910156l-32 56c-1.472656 2.585937-4.167968 4.03125-6.953124 4.03125zm0 0" />
	<path fill="#444" d="m256.007812 352.046875c-2.785156 0-5.480468-1.445313-6.953124-4.03125l-32-56c-2.191407-3.832031-.855469-8.71875 2.976562-10.910156 3.832031-2.210938 8.722656-.867188 10.914062 2.972656l32 56c2.191407 3.832031.855469 8.722656-2.976562 10.914063-1.25.71875-2.617188 1.054687-3.960938 1.054687zm0 0" />
	<path fill="#444" d="m232.007812 352.046875c-2.785156 0-5.480468-1.445313-6.953124-4.03125l-32-56c-2.191407-3.832031-.855469-8.71875 2.976562-10.910156 3.832031-2.210938 8.722656-.867188 10.914062 2.972656l32 56c2.191407 3.832031.855469 8.722656-2.976562 10.914063-1.25.71875-2.617188 1.054687-3.960938 1.054687zm0 0" />
	<path fill="#444" d="m312 296.046875h-24c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8h24c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m224 296.046875h-24c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8h24c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m351.992188 224.03125c-4.425782 0-8-3.574219-8-8 0-17.648438 14.351562-32 32-32 4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8-8.824219 0-16 7.175781-16 16 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m399.992188 200.078125h-24c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h24c4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m351.992188 256.0625c-4.425782 0-8-3.574219-8-8v-32.03125c0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8v32.03125c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m495.992188 224.03125c-4.425782 0-8-3.574219-8-8 0-8.824219-7.175782-16-16-16-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8 17.648437 0 32 14.351562 32 32 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m471.992188 200.078125h-24c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h24c4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m495.992188 256.0625c-4.425782 0-8-3.574219-8-8v-32.03125c0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8v32.03125c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m495.992188 256.0625h-144c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h144c4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m399.992188 200.078125c-4.425782 0-8-3.574219-8-8v-21.46875c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v21.46875c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m447.992188 200.03125c-4.425782 0-8-3.574219-8-8v-21.421875c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v21.421875c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m423.992188 184c-35.289063 0-64-28.695312-64-63.96875 0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8 0 26.457031 21.527343 47.96875 48 47.96875 26.472656 0 48-21.511719 48-47.96875 0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8 0 35.273438-28.710938 63.96875-64 63.96875zm0 0" />
	<path fill="#444" d="m424 112.03125c-20.640625 0-88-1.734375-88-24 0-11.933594 16.777344-16.664062 31.390625-19.457031 4.394531-.804688 8.53125 2.035156 9.363281 6.363281.832032 4.34375-2.027344 8.527344-6.363281 9.359375-6.957031 1.328125-11.496094 2.671875-14.390625 3.78125 9.671875 3.617187 32.6875 7.953125 68 7.953125s58.320312-4.335938 68-7.953125c-2.894531-1.101563-7.433594-2.453125-14.390625-3.78125-4.335937-.832031-7.183594-5.015625-6.363281-9.359375.832031-4.328125 4.960937-7.183594 9.363281-6.363281 14.613281 2.792969 31.390625 7.523437 31.390625 19.457031 0 22.265625-67.359375 24-88 24zm0 0" />
	<path fill="#444" d="m368 88.03125c-.574219 0-1.160156-.0625-1.742188-.183594-4.3125-.960937-7.035156-5.230468-6.082031-9.542968l16.007813-72.03125c.960937-4.3125 5.25-6.984376 9.542968-6.082032 4.3125.960938 7.035157 5.234375 6.082032 9.542969l-16.007813 72.035156c-.824219 3.726563-4.136719 6.261719-7.800781 6.261719zm0 0" />
	<path fill="#444" d="m479.992188 88.03125c-3.664063 0-6.976563-2.535156-7.808594-6.261719l-16.007813-72.035156c-.953125-4.308594 1.769531-8.582031 6.082031-9.542969 4.300782-.910156 8.582032 1.769532 9.542969 6.082032l16.007813 72.03125c.953125 4.3125-1.769532 8.582031-6.082032 9.542968-.574218.121094-1.160156.183594-1.734374.183594zm0 0" />
	<path fill="#444" d="m424 32.015625c-10.425781 0-17.863281-4.96875-24.441406-9.351563-5.359375-3.574218-9.984375-6.664062-15.558594-6.664062-4.425781 0-8-3.574219-8-8s3.574219-8 8-8c10.425781 0 17.863281 4.96875 24.441406 9.351562 5.359375 3.578126 9.984375 6.664063 15.558594 6.664063 5.566406 0 10.191406-3.085937 15.542969-6.65625 6.570312-4.390625 14.015625-9.359375 24.449219-9.359375 4.421874 0 8 3.574219 8 8s-3.578126 8-8 8c-5.578126 0-10.199219 3.089844-15.558594 6.664062-6.570313 4.382813-14.007813 9.351563-24.433594 9.351563zm0 0" />
	<path fill="#444" d="m480 88.03125h-112.007812c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h112.007812c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m479.992188 128.03125c-4.425782 0-8-3.574219-8-8v-20.574219c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v20.574219c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m367.992188 128.03125c-4.425782 0-8-3.574219-8-8v-20.574219c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v20.574219c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m406.839844 216.046875c-3.023438 0-5.910156-1.71875-7.265625-4.632813-1.855469-4.007812-.117188-8.765624 3.890625-10.621093l17.160156-7.953125c4.039062-1.863282 8.773438-.105469 10.621094 3.886718 1.859375 4.007813.121094 8.769532-3.886719 10.625l-17.160156 7.953126c-1.085938.511718-2.230469.742187-3.359375.742187zm0 0" />
	<path fill="#444" d="m423.992188 256.0625c-3.105469 0-6.054688-1.816406-7.351563-4.847656l-24-55.984375c-1.746094-4.054688.144531-8.757813 4.199219-10.503907 4.03125-1.765624 8.761718.128907 10.503906 4.203126l24 55.980468c1.746094 4.058594-.144531 8.761719-4.199219 10.503906-1.023437.449219-2.097656.648438-3.152343.648438zm0 0" />
	<path fill="#444" d="m399.992188 256.0625c-3.105469 0-6.054688-1.816406-7.351563-4.847656l-24-55.984375c-1.746094-4.054688.144531-8.757813 4.199219-10.503907 4.03125-1.765624 8.761718.128907 10.503906 4.203126l24 55.980468c1.746094 4.058594-.144531 8.761719-4.199219 10.503906-1.023437.449219-2.097656.648438-3.152343.648438zm0 0" />
	<path fill="#444" d="m441.152344 216.046875c-1.128906 0-2.265625-.230469-3.359375-.742187l-17.160157-7.953126c-4.007812-1.855468-5.753906-6.617187-3.890624-10.625 1.859374-4 6.59375-5.765624 10.625-3.886718l17.160156 7.953125c4.007812 1.855469 5.753906 6.613281 3.886718 10.621093-1.351562 2.914063-4.246093 4.632813-7.261718 4.632813zm0 0" />
	<path fill="#444" d="m424 256.0625c-1.054688 0-2.128906-.199219-3.152344-.648438-4.0625-1.742187-5.945312-6.445312-4.199218-10.503906l24-55.980468c1.734374-4.074219 6.464843-5.960938 10.503906-4.203126 4.0625 1.746094 5.945312 6.449219 4.199218 10.503907l-24 55.984375c-1.296874 3.03125-4.246093 4.847656-7.351562 4.847656zm0 0" />
	<path fill="#444" d="m447.992188 256.0625c-1.054688 0-2.128907-.199219-3.152344-.648438-4.0625-1.742187-5.945313-6.445312-4.199219-10.503906l24-55.980468c1.734375-4.074219 6.472656-5.960938 10.503906-4.203126 4.0625 1.746094 5.945313 6.449219 4.199219 10.503907l-24 55.984375c-1.296875 3.03125-4.246094 4.847656-7.351562 4.847656zm0 0" />
	<path fill="#444" d="m15.992188 224.03125c-4.425782 0-8-3.574219-8-8 0-17.648438 14.351562-32 32-32 4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8-8.824219 0-16 7.175781-16 16 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m63.992188 200.078125h-24c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h24c4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m15.992188 256.0625c-4.425782 0-8-3.574219-8-8v-32.03125c0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8v32.03125c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m159.992188 224.03125c-4.425782 0-8-3.574219-8-8 0-8.824219-7.175782-16-16-16-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8 17.648437 0 32 14.351562 32 32 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m135.992188 200.078125h-24c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h24c4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m159.992188 256.0625c-4.425782 0-8-3.574219-8-8v-32.03125c0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8v32.03125c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m159.992188 256.0625h-144c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h144c4.421874 0 8 3.578125 8 8 0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m63.992188 200.078125c-4.425782 0-8-3.574219-8-8v-21.46875c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v21.46875c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m111.992188 200.03125c-4.425782 0-8-3.574219-8-8v-21.421875c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v21.421875c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m87.992188 184c-35.289063 0-64-28.695312-64-63.96875 0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8 0 26.457031 21.527343 47.96875 48 47.96875 26.472656 0 48-21.511719 48-47.96875 0-4.421875 3.574218-8 8-8 4.421874 0 8 3.578125 8 8 0 35.273438-28.710938 63.96875-64 63.96875zm0 0" />
	<path fill="#444" d="m88 112.03125c-20.640625 0-88-1.734375-88-24 0-11.933594 16.777344-16.664062 31.390625-19.457031 4.402344-.804688 8.53125 2.035156 9.363281 6.363281.832032 4.34375-2.027344 8.527344-6.363281 9.359375-6.957031 1.328125-11.496094 2.671875-14.390625 3.78125 9.671875 3.617187 32.6875 7.953125 68 7.953125s58.320312-4.335938 68-7.953125c-2.894531-1.101563-7.433594-2.453125-14.390625-3.78125-4.335937-.832031-7.183594-5.015625-6.363281-9.359375.832031-4.328125 4.96875-7.183594 9.363281-6.363281 14.613281 2.792969 31.390625 7.523437 31.390625 19.457031 0 22.265625-67.359375 24-88 24zm0 0" />
	<path fill="#444" d="m32 88.03125c-.574219 0-1.160156-.0625-1.742188-.183594-4.3125-.960937-7.035156-5.230468-6.082031-9.542968l16.007813-72.03125c.960937-4.3125 5.257812-6.984376 9.542968-6.082032 4.3125.960938 7.035157 5.234375 6.082032 9.542969l-16.007813 72.035156c-.824219 3.726563-4.136719 6.261719-7.800781 6.261719zm0 0" />
	<path fill="#444" d="m143.992188 88.03125c-3.664063 0-6.976563-2.535156-7.808594-6.261719l-16.007813-72.035156c-.953125-4.308594 1.769531-8.582031 6.082031-9.542969 4.308594-.910156 8.582032 1.769532 9.542969 6.082032l16.007813 72.03125c.953125 4.3125-1.769532 8.582031-6.082032 9.542968-.574218.121094-1.160156.183594-1.734374.183594zm0 0" />
	<path fill="#444" d="m88 32.015625c-10.425781 0-17.863281-4.96875-24.441406-9.351563-5.359375-3.574218-9.984375-6.664062-15.558594-6.664062-4.425781 0-8-3.574219-8-8s3.574219-8 8-8c10.425781 0 17.863281 4.96875 24.441406 9.351562 5.359375 3.578126 9.984375 6.664063 15.558594 6.664063 5.566406 0 10.191406-3.085937 15.542969-6.65625 6.570312-4.390625 14.015625-9.359375 24.449219-9.359375 4.421874 0 8 3.574219 8 8s-3.578126 8-8 8c-5.578126 0-10.199219 3.089844-15.558594 6.664062-6.570313 4.382813-14.007813 9.351563-24.433594 9.351563zm0 0" />
	<path fill="#444" d="m144 88.03125h-112.007812c-4.425782 0-8-3.574219-8-8 0-4.421875 3.574218-8 8-8h112.007812c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m143.992188 128.03125c-4.425782 0-8-3.574219-8-8v-20.574219c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v20.574219c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m31.992188 128.03125c-4.425782 0-8-3.574219-8-8v-20.574219c0-4.425781 3.574218-8 8-8 4.421874 0 8 3.574219 8 8v20.574219c0 4.425781-3.578126 8-8 8zm0 0" />
	<path fill="#444" d="m72.007812 216.046875c-2.945312 0-5.777343-1.621094-7.167968-4.4375-1.96875-3.953125-.351563-8.761719 3.601562-10.730469l15.992188-7.949218c3.941406-1.96875 8.757812-.355469 10.726562 3.597656s.351563 8.761718-3.601562 10.730468l-15.992188 7.949219c-1.140625.578125-2.367187.839844-3.558594.839844zm0 0" />
	<path fill="#444" d="m103.992188 216.046875c-1.191407 0-2.40625-.261719-3.550782-.832031l-16-7.949219c-3.953125-1.96875-5.570312-6.769531-3.601562-10.730469 1.960937-3.949218 6.746094-5.566406 10.726562-3.597656l16 7.949219c3.953125 1.96875 5.570313 6.769531 3.601563 10.730469-1.398438 2.808593-4.230469 4.429687-7.175781 4.429687zm0 0" />
	<path fill="#444" d="m103.992188 216.046875c-1.199219 0-2.425782-.269531-3.566407-.839844-3.953125-1.976562-5.554687-6.792969-3.578125-10.734375l7.992188-15.96875c1.976562-3.953125 6.785156-5.527344 10.734375-3.574218 3.953125 1.972656 5.554687 6.789062 3.578125 10.734374l-7.992188 15.96875c-1.40625 2.800782-4.230468 4.414063-7.167968 4.414063zm0 0" />
	<path fill="#444" d="m72.007812 216.046875c-2.929687 0-5.761718-1.613281-7.160156-4.414063l-8.007812-15.96875c-1.984375-3.953124-.390625-8.761718 3.558594-10.734374 3.953124-1.984376 8.761718-.386719 10.738281 3.558593l8.007812 15.96875c1.984375 3.949219.390625 8.757813-3.558593 10.734375-1.144532.585938-2.378907.855469-3.578126.855469zm0 0" />
	<path fill="#444" d="m128 255.574219c-4.425781 0-8-3.574219-8-8v-55.503907c0-4.421874 3.574219-8 8-8s8 3.578126 8 8v55.503907c0 4.425781-3.574219 8-8 8zm0 0" />
	<path fill="#444" d="m48 255.574219c-4.425781 0-8-3.574219-8-8v-55.542969c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v55.542969c0 4.425781-3.574219 8-8 8zm0 0" />
		</SvgIcon>
	);
}

export default GodfatherIcon;
