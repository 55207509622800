import { coreApi } from "../../core";

export const createGame = (payload) =>
  coreApi
    .post("/admin-rating/game", payload)
    .then((res) => res.data)
    .catch(() => ({ success: false }));

export const updateGame = (gameId, payload) =>
  coreApi
    .post(`/admin-rating/game/${gameId}`, payload)
    .then((res) => res.data)
    .catch(() => ({ success: false }));

export const getGame = (gameId) =>
  coreApi
    .get(`/admin-rating/game/${gameId}`)
    .then((res) => res.data)
    .catch(() => ({ success: false }));
