export const LOCAL_STORAGE_PLACE = "LOCAL_STORAGE_PLACE";
export const LOCAL_STORAGE_CLUB = "LOCAL_STORAGE_CLUB";
export const LOCAL_STORAGE_CITY = "LOCAL_STORAGE_CITY";
export const LOCAL_STORAGE_COUNT = "LOCAL_STORAGE_COUNT";
export const LOCAL_STORAGE_TYPE = "LOCAL_STORAGE_TYPE";
export const LOCAL_STORAGE_RECOVERY = "LOCAL_STORAGE_RECOVERY";
export const LOCAL_STORAGE_CARDS = "LOCAL_STORAGE_CARDS";

export const GAME_TYPE_RATING = "rating";
export const GAME_TYPE_TEST = "test";
export const GAME_TYPE_UNRANKED = "unranked";
export const GAME_TYPE_CHILD = "child";
export const GAME_TYPE_TOURNAMENT = "tournament";
export const GAME_TYPES = [
	GAME_TYPE_RATING,
	GAME_TYPE_TOURNAMENT,
	GAME_TYPE_TEST,
	GAME_TYPE_UNRANKED,
	GAME_TYPE_CHILD
];
